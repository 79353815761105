import { gql } from '@apollo/client';

export const GET_ORDERS_MANAGER = gql`
    query GetOrdersManager(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryOrdersManagerOrderByOrderByClause!],
        $where: QueryOrdersManagerWhereWhereConditions,
    ) {
        ordersManager(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                order_id
                manager_id
                type_flower
                shipping_type
                count_flowers
                price_product
                price_actual
                price_shipping
                income
                order_date_time
                order{
                    id
                    status
                    type
                    order_status
                    payment_method
                    type_delivery
                    user_email
                    user_name
                    user_phone
                    price_total
                    price_product
                    price_shipping
                    shipping_date
                    shipping_time
                    # info{
                    #     fieldKey
                    #     fieldValue
                    # }
                    delivery_address
                    receiver_name
                    receiver_phone
                    message
                    locale
                    products{
                        product_id
                        title
                        image
                        full_price
                        size
                        unit_price
                        qty
                    }
                    orderLog{
                        id
                        order_id
                        remote_addr
                        http_user_agent
                        request_values
                        payment_info
                        created_at
                    }
                    order_date_time
                }
            }
        }
    }
`;

export const GET_ORDERS_MANAGER_CALENDAR = gql`
    query GetOrdersManagerCalendar(
        $date: String,
    ) {
        ordersManagerCalendar(
            date: $date
        ){
            id
            income
            salesCount
            orderDay
        }
    }
`;
