import React, { useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Row, Input, Table } from 'antd';

import { GET_CLIENTS } from "graphql/query/user-gql";
import { clientsColumns, clientsData } from "./clients-helper";
import { OrdersClientTable } from "../orders-user";


const { Search } = Input;

const ClientsTable = () => {

    const history = useHistory();
    const perPage = 50;

    let match = useRouteMatch( "/clients/page/:currentPage" );

    const [ searchText, setSearchText ] = useState( "" );
    const [ orderBy, setOrderBy ]       = useState();
    const [ filters, setFilters ]       = useState();

    const currentPage = match ? parseInt( match.params.currentPage ) : 1;

    const variables = {
        text: searchText,
        first: perPage,
        page: currentPage,
        orderBy,
        where: { ...filters }
    };

    const { data : { clients } = {} } = useQuery( GET_CLIENTS, {
        variables: { ...variables },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    } );

    const { paginatorInfo } = clients ?? {};

    return(
        <>
            <div className="table-header p-3">

                <Row className="title-header mb-2">

                    <h1 className="fs-18">Клиенты</h1>

                </Row>

                <Row className="filter-header">
                
                    {/* <OrdersFilters setFilters={ setFilters } defaultFilters={ defaultFilters } /> */}

                    <div className="w-100 my-2">
                        <Search 
                            className="search-header w-100"
                            placeholder="поиск"
                            onSearch={ ( value ) => setSearchText( value ) }  
                        />

                        <span className="float-right font-family-numeric">total: { !clients ? '0' : clients.paginatorInfo.total }</span>
                    </div>
                    
                </Row>

            </div>

            <Table 
                // locale={{
                //     emptyText: loading ? <Skeleton active={true} paragraph={{ rows: 30 }} /> : <Empty />
                // }}
                onChange={ ( pagination, filters, { column, order } ) => {
                    
                    if( column && order ){
                        
                        setOrderBy([
                            { 
                                column: column.columnIndex, 
                                order: order === "ascend" ? "ASC" : "DESC"  
                            }
                        ])

                    } else { setOrderBy( [] ) }

                }}
                className="clients-table"
                dataSource={ clientsData( clients?.data, variables ) }
                columns={ clientsColumns }
                expandable={
                    {     
                        expandedRowRender: record => <OrdersClientTable client={ record } />,
                        rowExpandable: record => record.email,
                    }
                }
                scroll={ { x:true } }
                bordered={ true }
                size="small"
                tableLayout='fixed'
                pagination={{
                    position: ["bottomRight", "topRight"],
                    pageSize: paginatorInfo?.perPage,
                    total: paginatorInfo?.total,
                    hideOnSinglePage: paginatorInfo?.total / paginatorInfo?.perPage > 1,
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/clients` : `/clients/page/${ page }`
                        history.push( path );
                    }
                }}
            />
        
        </>
    );
}

export default ClientsTable;