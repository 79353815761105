import { Table, Tag, Tooltip } from 'antd';
import { OrderManagerDeleteButton, OrderManagerEditFieldInput } from './fields';
import { OrderManagerEditViewModal } from '../modal';

import { CarTwoTone, ShopTwoTone } from '@ant-design/icons';


const ordersManagerColumns = [
    Table.EXPAND_COLUMN,
    {
        title: 'ID', dataIndex: 'id', columnIndex:'ID', sorter: true, align: 'center', className: 'table-id', width: "5%"
    },
    {
        title: '№ заказа', dataIndex: 'order_id',  columnIndex: 'ORDER_ID', sorter: true, align: 'center', width: "7%"
    },
    {
        title: 'Тип цветов', dataIndex: 'type_flower', columnIndex: 'TYPE_FLOWER', sorter: true, align: 'center', width: "7%"
    },
    {
        title: 'доставка', dataIndex: 'shipping_type', columnIndex: 'SHIPPING_TYPE', sorter: true, align: 'center', width: "9%"
    },
    {
        title: 'Кол. цветов', dataIndex: 'count_flowers', columnIndex: 'COUNT_FLOWERS', sorter: true, align: 'center', width: "7%"
    },
    {
        title: 'Цена цветов', dataIndex: 'price_product', columnIndex: 'PRICE_PRODUCT', sorter: true, align: 'center', width: "10%", className: 'table-price'
    },
    {
        title: 'Факт. цена', dataIndex: 'price_actual', columnIndex: 'PRICE_ACTUAL', sorter: true, align: 'center', width: "10%", className: 'table-price'
    },
    {
        title: 'Цена доставки', dataIndex: 'price_shipping', columnIndex: 'PRICE_SHIPPING', sorter: true, align: 'center', width: "10%", className: 'table-price'
    },
    {
        title: 'Доход', dataIndex: 'income', align: 'center', className: 'table-income', width: "10%"
    },
    {
        title: 'дата', dataIndex: 'order_date_time', columnIndex:'ORDER_DATE_TIME', sorter: true, align: 'center', width: "15%"
    },
    { 
        title: 'action', dataIndex: 'action', align: 'center', width: "10%"
    }
];

const emptyData = ( count = 5 ) => {
	
	const emptyDataJSX = [];
    let my = 'my-2';

    if( count !== 5 ){
        my = 'my-3';
    }

	for ( var i = 0; i < count; i++ ){
    
        emptyDataJSX.push(
            {
                key: `empty-order-manager-${i}`,
                id: <div className={`custom-sceleton ${ my }`}></div>,
                order_id: <div className={`custom-sceleton ${ my }`}></div>,
                type_flower: <div className={`custom-sceleton ${ my }`}></div>,
                shipping_type: <div className={`custom-sceleton ${ my }`}></div>,
                count_flowers: <div className={`custom-sceleton ${ my }`}></div>,
                price_product: <div className={`custom-sceleton ${ my }`}></div>,
                price_actual: <div className={`custom-sceleton ${ my }`}></div>,
                income: <div className={`custom-sceleton ${ my }`}></div>,
                price_shipping: <div className={`custom-sceleton ${ my }`}></div>,
                order_date_time: <div className={`custom-sceleton ${ my }`}></div>,
                order: <div className={`custom-sceleton ${ my }`}></div>,
                action: <div className={`custom-sceleton ${ my }`}></div>
            }
        );

	}

	return emptyDataJSX;

};


const ordersManagerData = ( ordersManager, variables, perPage ) => {

    if( !ordersManager ){
        return emptyData( perPage );
    }

    return ordersManager.map( orderManager => {
        
        let order_html    = '';
        let shipping_type = <Tooltip placement="top" title="самовывоз">
           <ShopTwoTone className='fs-20' twoToneColor="green"/>
        </Tooltip>;

        if( orderManager.shipping_type === 'delivery' ){
            shipping_type = <Tooltip placement="top" title="доставка">
                <CarTwoTone className='fs-20' />
            </Tooltip>;
        }

        if( orderManager.order_id ){
            order_html = <Tag className='font-family-numeric fs-15 fw-500' color={ orderTagColor( orderManager.order.order_status ) }>{ orderManager.order_id }</Tag>;
        }

        return {
            key: orderManager.id,
            id: <OrderManagerEditViewModal orderManager={ orderManager } />,
            order_id: order_html,
            type_flower: orderManager.type_flower,
            shipping_type: shipping_type,
            count_flowers: orderManager.count_flowers,
            price_product: <OrderManagerEditFieldInput orderMg={ orderManager } keyField={ 'price_product' }/>,
            price_actual: <OrderManagerEditFieldInput orderMg={ orderManager } keyField={ 'price_actual' }/>,
            income: orderManager.income,
            price_shipping: <OrderManagerEditFieldInput orderMg={ orderManager } keyField={ 'price_shipping' }/>,
            order_date_time: orderManager.order_date_time,
            order: orderManager.order ?? null,
            action: <OrderManagerDeleteButton orderManager={ orderManager } variables={ variables }/>
        };

    });

};

const orderTagColor = ( value ) => {


    switch( value ){

        case 'processing':
            return 'orange';

        case 'completed':
            return 'success';

        case 'canceled':
            return 'default';

        default:
            return 'default';
    }

}

export {
    ordersManagerColumns,
    ordersManagerData
};