import React from "react";
import { Row } from 'antd';

import AnalyticsSummaryWidget from "components/analytics-monthly/analytics-summary";
import { SummaryStatisticMonth, SummaryStatisticWeek } from "components/analytics-monthly/report-summary-statistic";
import UsersTop from "components/order-user/users-top";

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";

import './home.scss';

const columns = {
	span : 24,
    xs: 24,
    sm: 12,
	lg: 12,
	xl: 12,
	xxl: 12
};

const columnsUsersTop = {
	span : 24,
    xs: 24,
    sm: 12,
	lg: 8,
	xl: 8,
	xxl: 8
};

const HomeAppPage = () => {

    useActiveMenuItem( ["dashboard"] );

    return(
        <PageWrapDefault className="page-dashboard">

            <AnalyticsSummaryWidget />

            <Row gutter={16} className="mb-3">

                <SummaryStatisticWeek columns={ columns } />

                <SummaryStatisticMonth columns={ columns } />
            
            </Row>

            <Row gutter={16} className="mb-3">

                <UsersTop columns={ columnsUsersTop } variables={ { typeField: 'price_total', defaultCount: 5 } } />
                
                <UsersTop columns={ columnsUsersTop } variables={ { typeField: 'orders_count', defaultCount: 5 } } />

                <UsersTop columns={ columnsUsersTop } variables={ { typeField: 'delivery_count', defaultCount: 5 } } />

            </Row>
           
            
        </PageWrapDefault>
    )
};

export default HomeAppPage;