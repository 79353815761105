import React, { useState } from 'react';
import { Button, Drawer, Tooltip } from 'antd';

import { OrderManagerEditFieldInput } from 'components/order-manager/orders-manager-table/fields';
import { OrderManagerCreateForm } from 'components/order-manager/form';

import { SelectOutlined, ProfileOutlined } from '@ant-design/icons';

const OrderManagerDrawer = ( { order } ) => {

    const [ visible, setVisible ] = useState( false );

    const iconHtml = ( order ) => {

        if( order.orderManager ){
            return <Tooltip placement="top" title="просмотр продажи">
                <ProfileOutlined className="detail-order-manager fs-20" />
            </Tooltip>;
        }

        return <Tooltip placement="top" title="добавить продажу">
            <SelectOutlined className="detail-order-manager empty fs-20" />
        </Tooltip>;

    }

    const contentHtml = ( order ) => {

        if( order.orderManager ){

            return <>
                <p className='d-flex justify-content-between mb-2'>
                    <span className='w-50 fw-600'>ID</span>
                    <span className='w-50 text-center'>{ order.orderManager.id }</span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span className='w-50 fw-600'>Кол. цветов</span>
                    <span className='w-50'><OrderManagerEditFieldInput orderMg={ order.orderManager } keyField={ 'count_flowers' }/></span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span className='w-50 fw-600'>Цена цветов</span>
                    <span className='w-50'><OrderManagerEditFieldInput orderMg={ order.orderManager } keyField={ 'price_product' }/></span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span className='w-50 fw-600'>Факт. цена</span>
                    <span className='w-50'><OrderManagerEditFieldInput orderMg={ order.orderManager } keyField={ 'price_actual' }/></span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span className='w-50 fw-600'>Цена доставки</span>
                    <span className='w-50'><OrderManagerEditFieldInput orderMg={ order.orderManager } keyField={ 'price_shipping' }/></span>
                </p>
                <p className='d-flex justify-content-between mt-1'>
                    <span className='w-50 fw-600'>Доход</span>
                    <span className='w-50 text-center'>{ order.orderManager.income }</span>
                </p>
            </>;

        }

        return <OrderManagerCreateForm variables={ {} } order={ order } />;

    }

    return(
        <>
            <Button 
                onClick={ () => { setVisible( true ) }}
                type="text"
            >
                { iconHtml( order ) }
            </Button>
            <Drawer 
                // width={ width }
                title="Продажа"
                placement="right"
                onClose={ () => { setVisible( false ) }} 
                visible={visible}
                >
                    { contentHtml( order ) }
            </Drawer>
        </>
    );

};

export default OrderManagerDrawer;