import React from "react";
import { Divider, Col, Row } from 'antd';


const columns = {
	span : 24,
    xs: 24,
    sm: 24,
	lg: 8,
	xl: 8,
	xxl: 8
};

const OrderGeneralInfoBlock = ( { order } ) => {

    if( !order ){
        return null;
    }

    return(
        <Row className="order-logs">
            <Col { ...columns } className="">
                <Divider>General Info</Divider>

                <p><b>id:</b> <span>{ order.orderLog.id } </span></p>
                <p><b>order_id:</b> <span>{ order.orderLog.order_id }</span></p>
                <p><b>remote_addr:</b> <span>{ order.orderLog.remote_addr }</span></p>
                <p><b>http_user_agent:</b> <span>{ order.orderLog.http_user_agent }</span></p>
                <p><b>created_at:</b> <span>{ order.orderLog.created_at }</span></p>    
            </Col>

            <Col { ...columns } className="">
                <Divider>Request Values</Divider>
                
                { order.orderLog.request_values && Object.entries( order.orderLog.request_values ).map( ( [key, value] ) => {
                    
                        return (
                            <p key={ key }><b>{ key }:</b> <span>{ value }</span></p>
                        );
                        

                    }
                )}    
            </Col>

            <Col { ...columns } className="">
                <Divider>Payment Info</Divider>
                { order.orderLog.payment_info && Object.entries( order.orderLog.payment_info ).map( ( [key, value] ) => {
                    
                    return (
                        <p key={ key }><b>{ key }:</b> <span>{ value }</span></p>
                    );

                }
            )}  
            </Col>
            
        </Row>
    );


}

export default OrderGeneralInfoBlock;