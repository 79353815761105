
const productsTopColumns = [
    {
        title: 'ID', dataIndex: 'id', columnIndex:'ID', sorter: true, align: 'center', className: 'table-id', width: "5%"
    },
    {
        title: 'title', dataIndex: 'title',  columnIndex: 'TITLE', sorter: true, align: 'center', width: "30%"
    },
    {
        title: 'Тип цветов', dataIndex: 'type_flower', columnIndex: 'TYPE_FLOWER', sorter: true, align: 'center', width: "10%"
    },
    {
        title: 'Продажи', dataIndex: 'type_flower', columnIndex: 'TYPE_FLOWER', sorter: true, align: 'center', width: "10%"
    },
    {
        title: 'QTY Sale', dataIndex: 'type_flower', columnIndex: 'TYPE_FLOWER', sorter: true, align: 'center', width: "10%"
    }
];


const productsData = ( products, variables ) => {

    if( !products ){
        return [];
    }

    return products.map( product => {

        return {
            key: products.id,
            id: products.id,
        };

    });

};

export {
    productsTopColumns,
    productsData
};