import React, { useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Row, Input, Table, Skeleton, Empty } from 'antd';

import { GET_ORDERS } from "graphql/query/order-qql";

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";

import { ordersColumns, ordersData } from "components/order-user/orders-table/orders-table-helper";
import OrdersFilters from "components/order-user/orders-table/orders-filters";
import OrderBlock from "components/order-user/order-block";


const { Search } = Input;

const OrdersNewPage = () => {

    useActiveMenuItem( ["orders-new"], ["orders"] );

    const history        = useHistory();
    const defaultOrderBy = { column: "ORDER_DATE_TIME", order: "DESC" };
    const defaultFilters = { column: "ORDER_STATUS", operator: "EQ", value: "processing" };
    const perPage        = 50;

    let match = useRouteMatch( "/orders/new/page/:currentPage" );

    const [ searchText, setSearchText ] = useState ( "" );
    const [ orderBy, setOrderBy ]       = useState ( [ defaultOrderBy ] );
    const [ filters, setFilters ]       = useState( defaultFilters );

    const currentPage = match ? parseInt( match.params.currentPage ) : 1;

    const variables = {
        text: searchText,
        first: perPage,
        page: currentPage,
        orderBy,
        where: { ...filters }
    };

    const { data : { orders } = {}, loading } = useQuery( GET_ORDERS, {
        variables: { ...variables },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    } );

    const { paginatorInfo } = orders ?? {};

    return(
            
        <PageWrapDefault className="page orders-page fullscreen">
            <div className="table-header p-3">

                <Row className="title-header mb-4">

                    <h1 className="fs-18">Новые Заказы</h1>

                </Row>

                <Row className="filter-header">
                
                    <OrdersFilters setFilters={ setFilters } defaultFilters={ defaultFilters } orderStatus='processing' />

                    <div className="w-100 my-2">
                        <Search 
                            className="search-header w-100"
                            placeholder="поиск"
                            onSearch={ ( value ) => setSearchText( value ) }  
                        />

                        <span className="float-right font-family-numeric">total: { !orders ? '0' : orders.paginatorInfo.total }</span>
                    </div>
                    
                </Row>

            </div>

            <Table 
                className="orders-manager-table"
                locale={{
                    emptyText: loading ? <Skeleton active={true} paragraph={{ rows: 30 }} /> : <Empty />
                }}
                onChange={ ( pagination, filters, { column, order } ) => {
                    
                    if( column && order ){
                        
                        setOrderBy([
                            { 
                                column: column.columnIndex, 
                                order: order === "ascend" ? "ASC" : "DESC"  
                            }
                        ])

                    } else { setOrderBy( [ defaultOrderBy ] ) }

                }}
                columns={ ordersColumns }
                dataSource={ ordersData( orders?.data, variables ) }
                expandable={
                    {     
                        expandedRowRender: record => <OrderBlock order={ record.orderDetails } />,
                        rowExpandable: record => record.id,
                    }
                }
                scroll={ { x:true } }
                bordered={ true }
                tableLayout='fixed'
                pagination={{
                    pageSize: paginatorInfo?.perPage,
                    total: paginatorInfo?.total,
                    hideOnSinglePage: paginatorInfo?.total / paginatorInfo?.perPage > 1,
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/orders/new` : `/orders/new/page/${ page }`
                        history.push( path );
                    }
                }}
            />
            
        </PageWrapDefault>
        
    )

}

export default OrdersNewPage;