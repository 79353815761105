import React from "react";

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";
import { ClientsTable } from "components/clients";


const ClientsPage = () => {

    useActiveMenuItem( ["clients"] );

    return(
            
        <PageWrapDefault className="page clients-page fullscreen">
            
            <ClientsTable />

        </PageWrapDefault>
        
    )

}

export default ClientsPage;