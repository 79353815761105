import React, { useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Row, Input, Table, Skeleton, Empty } from 'antd';

import { GET_ORDERS_MANAGER } from "graphql/query/order-manager-qql";
import { ordersManagerColumns, ordersManagerData } from "./orders-manager-table-helper";
import OrdersManagerFilters from './orders-manager-filters';
import OrderBlock from "components/order-user/order-block";
import { OrderManagerCreateModal } from "../modal";

const { Search } = Input;

const OrdersManagerTable = () => {

    const history        = useHistory();
    const defaultOrderBy = { column: "ORDER_DATE_TIME", order: "DESC" };
    const perPage        = 50;

    let match = useRouteMatch( '/orders-manager/page/:currentPage' );

    const [ searchText, setSearchText ] = useState ( "" );
    const [ orderBy, setOrderBy ]       = useState ( [ defaultOrderBy ] );
    const [ filters, setFilters ]       = useState();

    const currentPage = match ? parseInt( match.params.currentPage ) : 1;

    const variables = {
        text: searchText,
        first: perPage,
        page: currentPage,
        orderBy,
        where: { ...filters }
    };

    const { data : { ordersManager } = {}, loading } = useQuery( GET_ORDERS_MANAGER, {
        variables: { ...variables },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    } );

    const { paginatorInfo } = ordersManager ?? {};

    return(
        <>
            <div className="table-header p-3">

                <Row className="title-header mb-4">

                    <h1 className="fs-18">Продажи</h1>

                    <OrderManagerCreateModal variables={ variables }/>

                </Row>

                <Row className="filter-header">
                    
                    <Search 
                        className="search-header w-100"
                        placeholder="поиск"
                        onSearch={ ( value ) => setSearchText( value ) }  
                    />

                    <OrdersManagerFilters setFilters={ setFilters } />

                    <span className="float-right font-family-numeric">total: { !ordersManager ? '0' : ordersManager.paginatorInfo.total }</span>

                </Row>

            </div>

            <Table 
                className="orders-manager-table"
                // locale={{
                //     emptyText: loading ? <Skeleton active={true} paragraph={{ rows: 30 }} /> : <Empty />
                // }}
                onChange={ ( pagination, filters, { column, order } ) => {
                    
                    if( column && order ){
                        
                        setOrderBy([
                            { 
                                column: column.columnIndex, 
                                order: order === "ascend" ? "ASC" : "DESC"  
                            }
                        ])

                    } else { setOrderBy( [ defaultOrderBy ] ) }

                }}
                columns={ ordersManagerColumns }
                dataSource={ ordersManagerData( ordersManager?.data, variables, perPage ) }
                expandable={
                    {
                        expandedRowRender: record => <OrderBlock order={ record.order } />,
                        rowExpandable: record => record.order_id,
                    }
                }
                scroll={ { x:true } }
                bordered={ true }
                tableLayout='fixed'
                pagination={{
                    position: ["bottomRight", "topRight"],
                    pageSize: paginatorInfo?.perPage,
                    total: paginatorInfo?.total,
                    hideOnSinglePage: paginatorInfo?.total / paginatorInfo?.perPage > 1,
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/orders-manager` : `/orders-manager/page/${ page }`
                        history.push( path );
                    }
                }}
            />
        
        </>
    );

}

export default OrdersManagerTable;