import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Card, Col, Row, Select, Progress, Statistic } from 'antd';

import { yearsList, priorityFieldPerMonth, percentColor, arrayMonth } from "components/helper-const/global-const"; 

import { GET_ANALYTICS_MONTHLY } from "graphql/query/statistic-ggl";

import { RetweetOutlined, SyncOutlined } from '@ant-design/icons';

const { Option } = Select;

const columns = {
	span : 12,
    xs: 12,
    sm: 8,
	lg: 8,
	xl: 6,
	xxl: 6
};

const MonthlyStatistics = () => {
    
    const [ selectYear, setSelectYear ] = useState( false );
    const [ selectTypeField, setSelectTypeField ] = useState( 'income' );
  
    const { data : { analytics_monthly } = {}, loading } = useQuery( GET_ANALYTICS_MONTHLY, {
        fetchPolicy: 'cache-first'
    } );

    let yearStatistic = [];
    let uniqueYears   = [];

    if( !loading && analytics_monthly ){

        yearStatistic = analytics_monthly.filter( ( item ) => {
            
            if( selectYear ){

                return item.year === selectYear;
            }

            return true;
        })

        yearStatistic.forEach( row => {

            if( !uniqueYears.includes( row.year ) ){
                uniqueYears.push(row.year)
            }
        
        });

    }
    
    return(
        <>
            <Row gutter={16} className="mb-4">
                
                <Col className="text-center" span={24}>

                    <Card type="inner" title="Статисика" bordered={true}>
                        
                        <Select 
                            defaultValue="all" 
                            style={{ width: 120 }} 
                            onSelect={ ( value ) => setSelectYear( value === 'all' ? false : parseInt( value ) ) }
                        >
                            <Option value="all">Все</Option>
                            { yearsList().map( ( el ) => { 
                                return <Option key={ el } value={ el }>{ el }</Option>
                            }) }
                            
                        </Select>

                        <Select 
                            defaultValue="income" 
                            style={{ width: 120 }} 
                            onSelect={ ( value ) => setSelectTypeField( value ) }
                        >
                            <Option value="income">Прибыль</Option>
                            <Option value="sales_count">Кол. продаж</Option>
                            <Option value="delivery_count">Доставки</Option>
                            <Option value="delivery_price">Сумма доставок</Option>
                            <Option value="type_users">Через сайт</Option>
                            <Option value="type_manager">Менеджер</Option>
                            <Option value="payment_method_cart">Оплата катой</Option>
                            <Option value="payment_method_cash">Оплата наличными</Option>
                            <Option value="type_cart">Корзина</Option>
                            <Option value="type_fast">Быстрый заказ</Option>

                        </Select>

                    </Card>

                </Col>

            </Row>

            {/* <Skeleton active={true} paragraph={{ rows: 8 }} />  */}
            <Row gutter={16} className="mb-3 month-progress-bar">
                
                { loading && yearsList().map( ( el ) => { 
                        return <Col key={ el } { ...columns } className="mb-3">
                                <Card title={ el } className="text-center"> 
                                    { arrayMonth.map( ( month, idx ) => {
                                            return <div key={ `empty-${idx}-${month}` }>
                                                <div className="custom-sceleton">
                                                </div>
                                                <div className="custom-sceleton">
                                                </div>
                                            </div>;
                                        })
                                    }
                                </Card>
                            </Col>
                    })
                }

                { uniqueYears.map( year => {

                        return (
                            <Col key={ year } { ...columns } className="mb-3">
                                <Card title={ year } className="text-center">

                                    { arrayMonth.map( ( month, idx ) => {

                                            const numberMonth  = idx + 1;

                                            const item = yearStatistic.find( ( row ) => year === row.year && numberMonth === row.month );

                                            let percent = 0;

                                            if( item && item[selectTypeField] ){
                                                percent = Math.round( item[selectTypeField] * 100 / priorityFieldPerMonth( selectTypeField ), 0 );
                                            }

                                            const strokeColor = percentColor( percent );

                                            return (
                                                <div key={ `${year}-${month}` }>
                                                    <div className="d-flex justify-content-between">
                                                        <span>{ month }  <SyncOutlined /></span>
                                                        <Statistic groupSeparator=" " value={ item ? item[selectTypeField] : 0 } />
                                                    </div>
                                                    <Progress 
                                                        strokeColor={ strokeColor }
                                                        format={ () => '' } 
                                                        percent={ item ? percent : 0 } 
                                                    />
                                                </div>
                                            );

                                        })
                                    }

                                    <div className="mt-2 d-flex justify-content-center">
                                        <span className="me-1">Приоритет: </span>
                                        <Statistic groupSeparator=" " value={ priorityFieldPerMonth( selectTypeField ) } />
                                    </div>

                                </Card>
                                
                            </Col>
                        );
                    } 
                )}
                
            </Row>
        
        </>
    )
};

export default MonthlyStatistics;