import { CarTwoTone, ShopTwoTone } from '@ant-design/icons';
// import { Link } from "react-router-dom";

const fieldTypeDeliveryText = ( val ) => {

    if( val === 'pickup' ){
    
        return 'Самовывоз';
    
    }

    return 'Доставка';

}

const fieldPaymentMethodText = ( val ) => {

    if( val === 'card' ){
    
        return 'Безналичный расчет';
    
    } else if( val === 'cash' ){

        return 'Оплата наличными в магазине';
    
    }

    return 'Оплата наличными курьеру';

}

const fieldDeliveryAddress = ( val ) => {

    if( !val ){
        return '';
    }

    return <p>
        <b>Курьерская доставка: </b>
        <a href={  `http://maps.google.com/?q=${ val }` }>
            { val }
        </a>
    </p>;

}

const fieldUserPhone = ( val ) => {

    if( !val ){
        return '';
    }

    return <p>
        <b>Телефон: </b>
        <a href={  `tel:${ val }` }>
            { val }
        </a>
    </p>;

}

const fieldReceiverPhone = ( val ) => {

    if( !val ){
        return '';
    }

    return <a href={  `tel:${ val }` }>
        { val }
    </a>;

}

const fieldTypeDeliveryIcon = ( val ) => {

    if( val === 'delivery' ){
        
        return <CarTwoTone className='fs-20' />;
    
    }

    return <ShopTwoTone className='fs-20' twoToneColor="green"/>;

}


export {
    fieldPaymentMethodText,
    fieldTypeDeliveryIcon,
    fieldDeliveryAddress,
    fieldUserPhone,
    fieldReceiverPhone,
    fieldTypeDeliveryText
}