import React from "react";
import { useQuery } from "@apollo/client";
import { Row, Table, Skeleton, Empty } from 'antd';

import { GET_ORDERS } from "graphql/query/order-qql";

import { ordersData } from "components/order-user/orders-table/orders-table-helper";
import OrderBlock from "components/order-user/order-block";


const ordersColumns = [
    Table.EXPAND_COLUMN,
    {
        title: 'ID', dataIndex: 'id', columnIndex:'ID', sorter: true, align: 'center', className: 'table-id', width: "5%"
    },
    {
        title: 'Тип', dataIndex: 'type', columnIndex: 'TYPE', sorter: true, align: 'center', width: "10%"
    },
    {
        title: 'Метод оплаты', dataIndex: 'payment_method', columnIndex: 'PAYMENT_METHOD', sorter: true, align: 'center', width: "10%"
    },
    {
        title: 'доставка', dataIndex: 'type_delivery', columnIndex: 'TYPE_DELIVERY', sorter: true, align: 'center', width: "10%"
    },
    {
        title: 'сумма', dataIndex: 'price_total', columnIndex:'PRICE_TOTAL', sorter: true, align: 'center', className: 'table-proce-total', width: "10%"
    },
    {
        title: 'статус', dataIndex: 'order_status', columnIndex:'ORDER_STATUS', sorter: true, align: 'center', width: "15%"
    },
    {
        title: 'дата доставки', dataIndex: 'shipping_date', columnIndex:'SHIPPING_DATE', sorter: true, align: 'center', width: "20%"
    },
    {
        title: 'дата заказа', dataIndex: 'order_date_time', columnIndex:'ORDER_DATE_TIME', sorter: true, align: 'center', width: "20%"
    }
];

const OrdersClientTable = ( { client } ) => {

    const variables = {
        first: 200,
        page: 1,
        orderBy: [ { column: "ORDER_DATE_TIME", order: "DESC" } ],
        where: { column: "USER_EMAIL", operator: "EQ", value: client.email }
    };

    const { data : { orders } = {}, loading } = useQuery( GET_ORDERS, {
        variables: { ...variables },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    } );

    return(
        <>
            <div className="table-header pt-2 ps-2">

                <Row className="title-header">

                    <h1 className="fs-15">Заказы: { !orders ? '0' : orders.paginatorInfo.total }</h1>

                </Row>

            </div>

            <Table 
                showHeader={ false }
                className="orders-client-table"
                locale={{
                    emptyText: loading ? <Skeleton active={ true } paragraph={{ rows: 5 }} /> : <Empty />
                }}
                columns={ ordersColumns }
                dataSource={ ordersData( orders?.data, variables ) }
                expandable={
                    {     
                        expandedRowRender: record => <OrderBlock order={ record.orderDetails } />,
                        rowExpandable: record => record.id,
                    }
                }
                // scroll={ { x:true } }
                bordered={ false }
                tableLayout='fixed'
                size="small"
                pagination={ false }
            />
        </>
    );

}

export default OrdersClientTable;