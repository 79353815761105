import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";

export default function OrdersRoute() {

    const { path } = useRouteMatch();
    
    return (
        <>
            <Switch>
                <Route path={`${path}`} exact>
                    <Pages.OrdersPage />
                </Route>
                <Route path={`${path}/page/:id`} exact>
                    <Pages.OrdersPage />
                </Route>
                <Route path={`${path}/new`} exact>
                    <Pages.OrdersNewPage />
                </Route>
                <Route path={`${path}/new/page/:id`} exact>
                    <Pages.OrdersNewPage />
                </Route>
                <Route path={`${path}/new-today`} exact>
                    <Pages.OrdersNewTodayPage />
                </Route>
                <Route path={`${path}/new-today/page/:id`} exact>
                    <Pages.OrdersNewTodayPage />
                </Route>
                <Route path={`${path}/new-tomorrow`} exact>
                    <Pages.OrdersNewTomorrowPage />
                </Route>
                <Route path={`${path}/new-tomorrow/page/:id`} exact>
                    <Pages.OrdersNewTomorrowPage />
                </Route>
            </Switch>
        </>
    );

}