import { gql } from '@apollo/client';


export const GET_ME = gql`
    query GetMe {
        me{
            id
            name
            email
            status
            role
            updated_at
            created_at
        }
    }
`;

export const GET_USERS = gql`
    query GetUsers(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryUsersOrderByOrderByClause!],
        $where: QueryUsersWhereWhereConditions
    ){
        users(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                name
                email
                status
                role
                updated_at
                created_at
            }
        }
    }
`;

export const GET_USERS_AUTOCOMPLETE = gql`
    query GetUsersAutocomplete(
        $text: String
    ){
        users(
            text: $text
        ){
            data{
                id
                name
                email
                status
                role
                updated_at
                created_at
            }
        }
    }
`;

export const GET_USER = gql`
    query GetUser(
        $id: ID,
    ) {
        user(
            id: $id,
        ){
            id
            name
            email
            status
            role
            updated_at
            created_at
        }
    }
`;


export const GET_CLIENTS = gql`
    query GetCliens(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryClientsOrderByOrderByClause!]
    ){
        clients(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                user_email
                user_name
                user_phone
                orders_count
                price_total
                income
                pickup_count
                delivery_count
                cart_count
                fast_count
                card_count
                cash_count
                courier_count
            }
        }
    }
`;