import React from "react";
import MonthlyStatistics from "components/analytics-monthly/monthly-statistics";

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";


const MonthlyStatisticsPage = () => {

    useActiveMenuItem( ["monthly-statistics"] );

    return(
        <PageWrapDefault className="page-dashboard"><MonthlyStatistics /></PageWrapDefault>
        
    )

}

export default MonthlyStatisticsPage;