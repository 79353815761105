import React, { useState } from 'react';

import { Modal, Button } from 'antd';
import { OrderManagerCreateForm } from '../form';


const OrderManagerCreateModal = ( { variables } ) => {

    const [isModalVisible, setIsModalVisible] = useState( false );

    const showModal = () => {
        setIsModalVisible( true );
    };
    
    // const handleOk = () => {
    //     setIsModalVisible( false );
    // };
    
    const handleCancel = () => {
        setIsModalVisible( false );
    };

    return (
        <>
            <Button
                className='custom' 
                type="primary" 
                onClick={ showModal }>
                Добавить продажу
            </Button>
            <Modal 
                title="Добавить продажу" 
                visible={ isModalVisible } 
                // onOk={ handleOk } 
                onCancel={ handleCancel }
                className="modal-order-manager-create"
            >
                <OrderManagerCreateForm variables={ variables } />
            </Modal>
        </>
      );

}


export default OrderManagerCreateModal;