import React from "react";
import { useQuery } from "@apollo/client";
import { Divider } from 'antd';

import { GET_SUMMARY_STATISTIC } from 'graphql/query/statistic-ggl';
import translationWord from 'components/helper-const/translation-word';


const emptyData = () => {
	
	const emptyDataJSX = [];

	for ( var i = 0; i < 13; i++ ){

		emptyDataJSX.push(
			<tr key={ `empty-summary-statistic-month-${i}` }>
				<td><div className="custom-sceleton my-1"></div></td>
				<td className="text-center"><div className="custom-sceleton my-1"></div></td>
			</tr>
			
		)

	}

	return emptyDataJSX;

};

const OrderManagerSummaryStatistic = ( { selectedValue } ) => {

    const { data : { summaryStatistic } = {}, loading } = useQuery( GET_SUMMARY_STATISTIC, {
		variables: {
            date_from: selectedValue.format("YYYY-MM-DD"),
            date_to:   selectedValue.format("YYYY-MM-DD"),
        },
        fetchPolicy: 'cache-first'
        // fetchPolicy: 'network-only'
    } );

    return(<>
        
        <Divider className="fw-600 mt-2">Отчет</Divider>
        
        { loading && 
            <table className="w-100 summary-statistic-month">
                <tbody>
                    { emptyData() }
                </tbody>
                <tfoot></tfoot>
            </table>
        }

        { !loading &&
           
            <table className="w-100 summary-statistic-month">

                <tbody>
                    <tr>
                        <td>
                            { translationWord['income'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.income ?? 0 }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            { translationWord['sales_count'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.sales_count ?? 0 }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            { translationWord['price_product'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.price_product ?? 0 }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            { translationWord['price_actual'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.price_actual ?? 0 }
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                            { translationWord['delivery_count'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.delivery_count ?? 0 }
                        </td>
                    </tr>

                    <tr>
                        <td>
                            { translationWord['delivery_price'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.delivery_price ?? 0 }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            { translationWord['flowers_count'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.flowers_count ?? 0 }
                        </td>
                    </tr>

                    <tr>
                        <td>
                            { translationWord['type_users'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.type_users ?? 0 }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            { translationWord['type_manager'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.type_manager ?? 0 }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            { translationWord['type_fast'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.type_fast ?? 0 }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            { translationWord['type_cart'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.type_cart ?? 0 }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            { translationWord['payment_method_cart'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.payment_method_cart ?? 0 }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            { translationWord['payment_method_cash'] }
                        </td>
                        <td className="text-center">
                            { summaryStatistic?.payment_method_cash ?? 0 }
                        </td>
                    </tr>

                </tbody>
            </table>
        }
    </>);

};

export default OrderManagerSummaryStatistic;