import { gql } from '@apollo/client';


export const USER_LOGIN = gql`
    mutation UserLogin($data: UserLoginInput!) {
        userLogin(input: $data){
            access_token
        }
    }
`;

export const USER_LOGOUT = gql`
    mutation UserLogout{
        userLogout {
            status
            message
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation UserForgotPassword($input: UserForgotPasswordInput!) {
        userForgotPassword(input: $input){
            label
            message
        }
    }`;

export const CHANGE_FORGOT_PASSWORD = gql`
    mutation ChangeForgotPassword(
        $password: String!
        $token: String!
    ){
        userUpdateForgotPassword(
            password: $password
            token: $token
        ){
            label
            message
        }
    }`;
