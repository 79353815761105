import { gql } from '@apollo/client';

export const ORDERS_DELETE = gql`
    mutation OrderDelete(
        $id: ID!
    ) {
        orderDelete(
            id: $id
        ){
            label
            message
        }
    }
`;


export const ORDERS_EDIT_STATUS_UPDATE = gql`
    mutation OrderEditStatus(
        $id: ID!
        $type: OrderUpdateStatusEnum
    ) {
        orderEditStatus(
            id: $id,
            type: $type
        ){
            label
            message
            order{
                id
                status
                type
                order_status
                payment_status
                payment_method
                type_delivery
                user_email
                user_name
                user_phone
                price_total
                price_product
                price_shipping
                shipping_date
                shipping_time
                delivery_address
                receiver_name
                receiver_phone
                message
                locale
                products{
                    product_id
                    title
                    image
                    full_price
                    size
                    unit_price
                    qty
                }
                orderLog{
                    id
                    order_id
                    remote_addr
                    http_user_agent
                    request_values
                    payment_info
                    created_at
                }
                order_date_time
            }
        }
    }
`;