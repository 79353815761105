import React from "react";
import { Input } from 'antd';
import { useMutation } from "@apollo/client";

import { ORDERS_MANAGER_FIELD_UPDATE } from "graphql/mutation/order-manager-qql";
import { errorNotification, successNotification } from "components/request-result";


const OrderManagerEditFieldInput = ( { orderMg, keyField } ) => {

    const value  = orderMg[keyField].toString();

    const [orderManagerFieldUpdate, { loading }] = useMutation( ORDERS_MANAGER_FIELD_UPDATE,
        {
            update( cache, { data } ) {

                const {
                    orderManagerFieldUpdate : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });
                
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <>
            <Input
                defaultValue={ value }
                className="text-center"
                onBlur={ ( e ) => {

                    if( !loading && e.target.value !== value ){

                        orderManagerFieldUpdate({
                            variables: {
                                id: orderMg.id,
                                keyField: keyField,
                                value: parseInt( e.target.value )
                            }
                        }).catch( ( error ) => {
                            errorNotification( error );
                        });

                    }
                }}
            />
        </>
    );

} 

export default OrderManagerEditFieldInput;