import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Popconfirm } from 'antd';

import { GET_ORDERS_MANAGER } from "graphql/query/order-manager-qql";
import { ORDERS_MANAGER_DELETE } from "graphql/mutation/order-manager-qql";

import { errorNotification, successNotification } from "components/request-result";
import { DeleteOutlined } from '@ant-design/icons';


const OrderManagerDeleteButton = ( { orderManager, variables } ) => {

    const [visible, setVisible] = React.useState(false);

    const [orderManagerDeleteRequest, { loading }] = useMutation( ORDERS_MANAGER_DELETE,
        {
            update(cache, { data }) {

                const {
                    orderManagerDelete : {
                        label,
                        message
                    }
                } = data;
                
                const { ordersManager } = cache.readQuery({
                    query: GET_ORDERS_MANAGER,
                    variables
                });
            
                cache.writeQuery({
                    query: GET_ORDERS_MANAGER,
                    variables,
                    data: {
                        ordersManager: {
                            ...ordersManager,
                            paginatorInfo:{
                                ...ordersManager.paginatorInfo,
                                total: ordersManager.paginatorInfo.total - 1
                            },
                            data : [
                                ...ordersManager.data.filter( ( item ) => item.id !== orderManager.id )
                            ]
                        }
                    },
                });

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        <>
            <Popconfirm
                cancelText="Нет"
                okText="Да"
                title={ <>Вы уверены, что хотите удалить запись <b>{ orderManager.id }</b> ?</> }
                visible={ visible }
                onConfirm={ () => {
                        orderManagerDeleteRequest({
                            variables: {
                                id: orderManager.id
                            }
                        })
                    }
                }
                okButtonProps={ { loading } }
                onCancel={ () => setVisible( false ) }
                >
                <Button
                    className="order-manager-button-delete"
                    type="text"
                    onClick={ () => setVisible( true ) }
                >
                    <DeleteOutlined className="fs-20" />
                    
                </Button>
            </Popconfirm>
        </>
    );

};

export default OrderManagerDeleteButton;