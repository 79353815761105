import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";

export default function ProductsTopPage() {

    const { path } = useRouteMatch();
    
    return (
        <>
            <Switch>
                <Route path={`${path}/top`} exact>
                    <Pages.ProductsTopPage />
                </Route>
                <Route path={`${path}/top/page/:id`} exact>
                    <Pages.ProductsTopPage />
                </Route>
            </Switch>
        </>
    );

}