import React from "react";
import { List, Image } from 'antd';


const productsData = ( products ) => {

    if( !products ){
        return [];
    }

    return products.map( product => 
        {
            return {
                product_id: product.product_id,
                title: product.title,
                image: product.image,
                full_price: product.full_price,
                unit_price: product.unit_price,
                size: product.size ?? null,
                qty: product.qty
            };
        }
    );

};

const OrderProductsList = ( { order } ) => {

    if( !order ){
        return null;
    }

    return(
        <List
            className="order-products-list"
            itemLayout="horizontal"
            dataSource={ productsData( order.products ) }
            renderItem={ item => (
            <List.Item>
                <List.Item.Meta
                    avatar={
                        <Image
                            width={30}
                            src={ item.image }
                        />
                    }
                    title={ <>
                        <span className="w-100 d-block">{ item.title } { item.size ? `(Высота: ${item.size} см)` : '' }</span> 
                        <span className="w-100 d-block">кол-во: { item.qty } шт. x { item.unit_price } = { item.unit_price * item.qty } грн </span>
                    </>}
                />
            </List.Item>
            )}
        />
    );


}

export default OrderProductsList;