import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Popconfirm, Tooltip } from 'antd';

import { GET_ORDERS } from "graphql/query/order-qql";
import { ORDERS_DELETE } from "graphql/mutation/order-qql";

import { errorNotification, successNotification } from "components/request-result";
import { DeleteOutlined } from '@ant-design/icons';


const OrderDeleteButton = ( { order, variables } ) => {

    const [visible, setVisible] = React.useState(false);

    const [orderDeleteRequest, { loading }] = useMutation( ORDERS_DELETE,
        {
            update(cache, { data }) {

                const {
                    orderDelete : {
                        label,
                        message
                    }
                } = data;
                
                const { orders } = cache.readQuery({
                    query: GET_ORDERS,
                    variables
                });
            
                cache.writeQuery({
                    query: GET_ORDERS,
                    variables,
                    data: {
                        orders: {
                            ...orders,
                            paginatorInfo:{
                                ...orders.paginatorInfo,
                                total: orders.paginatorInfo.total - 1
                            },
                            data : [
                                ...orders.data.filter( ( item ) => item.id !== order.id )
                            ]
                        }
                    },
                });

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        <>
            <Popconfirm
                cancelText="Нет"
                okText="Да"
                title={ <><b>Удалить заказ { order.id }</b> ?</> }
                visible={ visible }
                onConfirm={ () => {
                        orderDeleteRequest({
                            variables: {
                                id: order.id
                            }
                        })
                    }
                }
                okButtonProps={ { loading } }
                onCancel={ () => setVisible( false ) }
                >
                <Button
                    className="order-button-delete"
                    type="text"
                    onClick={ () => setVisible( true ) }
                >
                    
                    <Tooltip placement="top" title="удалить заказ">
                        <DeleteOutlined className="fs-20" />
                    </Tooltip>
                    
                </Button>
            </Popconfirm>
        </>
    );

};

export default OrderDeleteButton;