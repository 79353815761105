import React from "react";
import { Row, Col } from 'antd';

const PageWrapDefault = ({
    className = "",
    columns = {
        span : 24,
        lg: 22,
        xl: 20,
        xxl: 18
    },
    children,
    ...props
}) => {


    return(
        <Row justify="center" className={ `page ${ className }` }>
            <Col { ...columns }>
                { children }
            </Col>
        </Row>
    );

};

export default PageWrapDefault;