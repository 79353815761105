import React from "react";
import { Switch, Route } from 'react-router-dom';

import { Pages } from "components/pages";
import OrdersManagerRoute from "./orders-manager/orders-manager";
import OrdersRoute from "./orders/orders-routes";
import ClientsRoute from "./clients/clients-routes";
import ProductsRoute from "./products/products-route";

const AppRoutes = () => {

    return(
        <Switch>
            <Route path="/" exact>
                <Pages.HomeAppPage />
            </Route>
            <Route path="/system-information" exact>
                <Pages.SystemInfoPage />
            </Route>
            <Route path="/monthly-statistics" exact>
                <Pages.MonthlyStatisticsPage />
            </Route>
            <Route path="/orders-manager">
                <OrdersManagerRoute />
            </Route>
            <Route path="/orders">
                <OrdersRoute />
            </Route>
            <Route path="/clients">
                <ClientsRoute />
            </Route>
            <Route path="/products">
                <ProductsRoute />
            </Route>
            <Route path="*">
                <Pages.Page404 />
            </Route>
        </Switch>
    );
};

export default AppRoutes;