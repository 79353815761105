import { Table, Alert, Tooltip } from 'antd';
import { OrderDeleteButton, OrderApproveButton, OrderCancelButton } from '../fields';
import { OrderManagerDrawer } from '../drawer';

import { CarTwoTone, ShopTwoTone, ShoppingCartOutlined, 
    CreditCardTwoTone, RocketTwoTone, UserSwitchOutlined, 
    WalletOutlined } from '@ant-design/icons';


const ordersColumns = [
    Table.EXPAND_COLUMN,
    {
        title: 'ID', dataIndex: 'id', columnIndex:'ID', sorter: true, align: 'center', className: 'table-id', width: "7%"
    },
    {
        title: 'Тип', dataIndex: 'type', columnIndex: 'TYPE', sorter: true, align: 'center', width: "6%"
    },
    {
        title: 'Метод оплаты', dataIndex: 'payment_method', columnIndex: 'PAYMENT_METHOD', sorter: true, align: 'center', width: "5%"
    },
    {
        title: 'доставка', dataIndex: 'type_delivery', columnIndex: 'TYPE_DELIVERY', sorter: true, align: 'center', width: "7%"
    },
    {
        title: 'сумма', dataIndex: 'price_total', columnIndex:'PRICE_TOTAL', sorter: true, align: 'center', className: 'table-proce-total', width: "14%"
    },
    {
        title: 'статус', dataIndex: 'order_status', columnIndex:'ORDER_STATUS', sorter: true, align: 'center', width: "17%"
    },
    {
        title: 'дата доставки', dataIndex: 'shipping_date', columnIndex:'SHIPPING_DATE', sorter: true, align: 'center', width: "13%"
    },
    {
        title: 'дата заказа', dataIndex: 'order_date_time', columnIndex:'ORDER_DATE_TIME', sorter: true, align: 'center', width: "13%"
    },
    { 
        title: 'action', dataIndex: 'action', align: 'center', className: 'table-action', width: "18%"
    }
];

const ordersData = ( orders, variables ) => {

    if( !orders ){
        return [];
    }

    return orders.map( order => {

        let type_delivery = <Tooltip placement="top" title="самовывоз">
           <ShopTwoTone className='fs-20' twoToneColor="green"/>
        </Tooltip>;
        
        let type = <Tooltip placement="top" title="корзина">
           <ShoppingCartOutlined className='fs-20' style={{ color: '#6b9915' }}/>
        </Tooltip>;

        let payment_method = <Tooltip placement="top" title="наличными">
            <WalletOutlined className='fs-20' style={{ color: '#e91e63' }}/>
        </Tooltip>;

        let order_status   = <Alert message="выполнен" type="success" showIcon />;

        if( order.order_status === 'processing' ){
            order_status = <Alert message="в обработке" type="warning" showIcon />;
        }

        if( order.order_status === 'canceled' ){
            order_status = <Alert message="отменен" className='cancel-order' showIcon />;
        }

        if( order.payment_method === 'card' ){
            payment_method = <Tooltip placement="top" title="картой">
                <CreditCardTwoTone className='fs-20' twoToneColor="#00008b" />
            </Tooltip>;
        }

        if( order.payment_method === 'courier' ){
            payment_method = <Tooltip placement="top" title="курьеру">
                <UserSwitchOutlined className='fs-18' style={{ color: '#5bb300' }} />
            </Tooltip>;
        }

        if( order.type_delivery === 'delivery' ){
            type_delivery = <Tooltip placement="top" title="доставка">
                <CarTwoTone className='fs-20' />
            </Tooltip>;
        }

        if( order.type === 'fast' ){
            type = <Tooltip placement="top" title="быстрый заказ">
                <RocketTwoTone className='fs-20' twoToneColor="purple" />
            </Tooltip>;
        }

        return {
            key: order.id,
            id: order.id,
            type: type,
            payment_method: payment_method,
            type_delivery: type_delivery,
            price_total: `${order.price_total} грн`,
            order_status: order_status,
            shipping_date: <>{ order.shipping_time } <b>{order.shipping_date}</b></>,
            order_date_time: order.order_date_time,
            action: <>
                <OrderManagerDrawer order={ order } />
                <OrderApproveButton order={ order } />
                <OrderCancelButton order={ order } />
                <OrderDeleteButton order={ order } variables={ variables } />
            </>,
            orderDetails: order
        };

    });

};

export {
    ordersColumns,
    ordersData
};