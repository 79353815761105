import React  from "react";
import { useQuery } from "@apollo/client";
import { Table, Skeleton, Empty, Divider } from 'antd';

import { GET_ORDERS_MANAGER } from "graphql/query/order-manager-qql";
import { ordersManagerData } from "../orders-manager-table/orders-manager-table-helper";


const ordersManagerColumns = [
    {
        title: 'ID', dataIndex: 'id', columnIndex:'ID', align: 'center', className: 'table-id', width: "15%"
    },
    // {
    //     title: 'доставка', dataIndex: 'shipping_type', columnIndex: 'SHIPPING_TYPE', align: 'center', width: "5%"
    // },
    {
        title: 'Цена цветов', dataIndex: 'price_product', columnIndex: 'PRICE_PRODUCT', align: 'center', width: "25%", className: 'table-price'
    },
    {
        title: 'Факт. цена', dataIndex: 'price_actual', columnIndex: 'PRICE_ACTUAL', align: 'center', width: "25%", className: 'table-price'
    },
    {
        title: 'Цена доставки', dataIndex: 'price_shipping', columnIndex: 'PRICE_SHIPPING', align: 'center', width: "20%", className: 'table-price'
    },
    {
        title: 'Доход', dataIndex: 'income', align: 'center', className: 'table-income', width: "15%"
    }
];

const OrderManagerDay = ( { selectedValue } ) => {

    const variables = {
        first: 100,
        page: 1,
        where: { 
            column: "ORDER_DATE_TIME",
            operator: "BETWEEN",
            value: [
                selectedValue.format("YYYY-MM-DD 00:00:00"), 
                selectedValue.format("YYYY-MM-DD 23:59:59")
            ]
        }
    };

    const { data : { ordersManager } = {}, loading } = useQuery( GET_ORDERS_MANAGER, {
        variables: { ...variables },
        // fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    } );
    
    return(
        <>
            <Divider className="fw-600 mt-0">Продажи</Divider>

            <Table 
                className="orders-manager-table day p-0"
                locale={{
                    emptyText: loading ? <Skeleton active={ true } paragraph={{ rows: 4 }} /> : <Empty />
                }}
                columns={ ordersManagerColumns }
                dataSource={ ordersManagerData( ordersManager?.data, variables ) }
                scroll={ { x:true } }
                bordered={ false }
                tableLayout='fixed'
                pagination={ false }
            />
        </>
    );

}

export default OrderManagerDay;