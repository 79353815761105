import React, { useState } from 'react';

import { Modal, Button } from 'antd';

import { OrderManagerEditFieldInput } from '../orders-manager-table/fields';


const OrderManagerEditViewModal = ( { orderManager } ) => {

    const [ visible, setVisible ] = useState( false );

    const handleCancel = () => {
        setVisible( false );
    };
    
    return (
        <>
            <Button 
                className='fw-600' 
                type="text"
                onClick={ () => { setVisible( true ) }}
            >
                { orderManager.id }
            </Button>
            <Modal 
                title={`ID ${ orderManager.id }`}
                onClose={ () => { setVisible( false ) }} 
                onCancel={ handleCancel }
                visible={ visible }
                className="modal-order-manager-create"
            >
                <p className='d-flex justify-content-between mb-2'>
                    <span className='w-50 fw-600'>ID</span>
                    <span className='w-50 text-center'>{ orderManager.id }</span>
                </p>
                <p className='d-flex justify-content-between mb-2'>
                    <span className='w-50 fw-600'>ORDER</span>
                    <span className='w-50 text-center'>{ orderManager.order ? orderManager.order.id : 'n/a' }</span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span className='w-50 fw-600'>Кол. цветов</span>
                    <span className='w-50'><OrderManagerEditFieldInput orderMg={ orderManager } keyField={ 'count_flowers' }/></span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span className='w-50 fw-600'>Цена цветов</span>
                    <span className='w-50'><OrderManagerEditFieldInput orderMg={ orderManager } keyField={ 'price_product' }/></span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span className='w-50 fw-600'>Факт. цена</span>
                    <span className='w-50'><OrderManagerEditFieldInput orderMg={ orderManager } keyField={ 'price_actual' }/></span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span className='w-50 fw-600'>Цена доставки</span>
                    <span className='w-50'><OrderManagerEditFieldInput orderMg={ orderManager } keyField={ 'price_shipping' }/></span>
                </p>
                <p className='d-flex justify-content-between mt-1'>
                    <span className='w-50 fw-600'>Доход</span>
                    <span className='w-50 text-center'>{ orderManager.income }</span>
                </p>
            </Modal>
        </>
      );

}


export default OrderManagerEditViewModal;