import React from "react";
import { Col, Row, Collapse } from 'antd';

import { fieldPaymentMethodText, fieldDeliveryAddress, fieldUserPhone, fieldReceiverPhone, fieldTypeDeliveryText } from "../helper/order-helper";
import OrderProductsList from "../products-list";
import { OrderGeneralInfoBlock } from "components/order-log/block";

const columns = {
	span : 24,
    xs: 24,
    sm: 24,
	lg: 8,
	xl: 8,
	xxl: 8
};

const { Panel } = Collapse;


const OrderBlock = ( { order } ) => {

        if( !order ){
            return null;
        }

        return(
            <div>
                <Row className="mt-2 order-block-details px-3">
                    {/* <Col { ...columns } className="order-col-detail"> */}
                        {/* <p className="title"><b>Информация заказа</b></p> */}
                        
                        {/* <p><b>Дата заказа:</b> <span>{ order.order_date_time }</span></p> */}

                        {/* <p><b>Product:</b></p> */}

                        {/* <OrderProductsList order={ order } /> */}
                    
                    {/* </Col> */}
                    
                    <Col { ...columns } className="order-col-detail">
                        <p className="title"><b> Полная информация о заказе</b></p>                        
                        <p><b>Дата заказа:</b> <span>{ order.order_date_time }</span></p>
                        <p><b>Способ оплаты:</b> <span>{ fieldPaymentMethodText( order.payment_method )  }</span></p>

                        <p><b>{ fieldTypeDeliveryText( order.type_delivery ) }:</b> <span>{ order.shipping_time } { order.shipping_date }</span></p>

                        { order.receiver_name && 
                            <p>
                                <b>Получатель:</b>
                                <span>{ order.receiver_name }, { fieldReceiverPhone( order.receiver_phone ) }</span>
                            </p>
                        }

                        { fieldDeliveryAddress( order.delivery_address ) }

                    </Col>

                    <Col { ...columns } className="order-col-detail">
                        <p className="title"><b>Информация покупателя</b></p>
                        
                        <p><b>Имя:</b> <span>{ order.user_name } </span></p>
                        <p><b>Email:</b> <span>{ order.user_email } </span></p>
                        { fieldUserPhone( order.user_phone ) }

                        <p><b>Язык:</b> <span>{ order.locale } </span></p>
                    
                    </Col>
                    
                    <Col { ...columns } className="order-col-detail">
                        <p className="title"><b>Цена</b></p>
                        <p><b>Товар:</b></p>
                        <OrderProductsList order={ order } />
                        <p><b>Сумма за товар:</b> { order.price_product } грн.</p>
                        <p><b>Доставка:</b> { order.price_shipping } грн.</p>
                        <p><b>Итого к оплат:</b> { order.price_total } грн.</p>
                    
                    </Col>
                </Row>
                <Row className="mt-2 px-3">
                    <Col span={24}>
                        <Collapse >
                            <Panel header="Logs" key="general_info">
                                <OrderGeneralInfoBlock order={ order } />
                            </Panel>
                            {/* <Panel header="Payment Info" key="payment_info">
                                <OrderPaymentInfoBlock order={ order } />
                            </Panel>
                            <Panel header="Request Values" key="request_values">
                                <OrderRequestValuesBlock order={ order } />
                            </Panel> */}
                        </Collapse>
                    </Col>
                    
                </Row>
               
            </div>
        );

}

export default OrderBlock;