import React from 'react';
import { Card, Col } from 'antd';
import { useQuery } from "@apollo/client";

import { GET_SUMMARY_STATISTIC } from 'graphql/query/statistic-ggl';
import translationWord from 'components/helper-const/translation-word';


const emptyData = () => {
	
	const emptyDataJSX = [];

	for ( var i = 0; i < 13; i++ ){

		emptyDataJSX.push(
			<tr key={ `empty-summary-statistic-month-${i}` }>
				<td><div className="custom-sceleton my-1"></div></td>
				<td className="text-center"><div className="custom-sceleton my-1"></div></td>
			</tr>
			
		)

	}

	return emptyDataJSX;

};


const SummaryStatisticWeek = ( { columns } ) => {

	const { data : { summaryStatistic } = {}, loading } = useQuery( GET_SUMMARY_STATISTIC, {
		variables: {
            type: "week",
        },
        fetchPolicy: 'cache-first'
    } );

    return (
		<>
			{ loading && 
				<Col { ...columns } className="w-50">
					<Card type="inner" title="Текущая неделя" bordered={true}>
						<table className="w-100 summary-statistic-month">
							<tbody>
								{ emptyData() }
							</tbody>
						</table>
					</Card>
				</Col>
			}

			{ summaryStatistic &&
				<Col { ...columns } className="w-50">
					<Card type="inner" title="Текущая неделя" bordered={true}>

						<table className="w-100 summary-statistic-month">

							<tbody>

							{/* { !!summaryStatistic && Object.entries( summaryStatistic ).map( ( [key, value] ) => {

									if( key !== '__typename' && key !== 'id' && key !== 'year' && key !== 'month' && key !== 'title' ){
										return (<tr key={ `summary-${key}` }>
												<td>
													{ translationWord[key] }					
												</td>
												<td className="text-center">
												{ value }					
												</td>
										</tr>);
									}	
								}
							)} */}

								<tr>
									<td>
										{ translationWord['income'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.income }
									</td>
								</tr>
								<tr>
									<td>
										{ translationWord['sales_count'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.sales_count }
									</td>
								</tr>
								<tr>
									<td>
										{ translationWord['price_product'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.price_product }
									</td>
								</tr>
								<tr>
									<td>
										{ translationWord['price_actual'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.price_actual }
									</td>
								</tr>
								
								<tr>
									<td>
										{ translationWord['delivery_count'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.delivery_count }
									</td>
								</tr>

								<tr>
									<td>
										{ translationWord['delivery_price'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.delivery_price }
									</td>
								</tr>

								<tr>
									<td>
										{ translationWord['flowers_count'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.flowers_count }
									</td>
								</tr>

								<tr>
									<td>
										{ translationWord['type_users'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.type_users }
									</td>
								</tr>
								<tr>
									<td>
										{ translationWord['type_manager'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.type_manager }
									</td>
								</tr>
								<tr>
									<td>
										{ translationWord['type_fast'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.type_fast }
									</td>
								</tr>
								<tr>
									<td>
										{ translationWord['type_cart'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.type_cart }
									</td>
								</tr>
								<tr>
									<td>
										{ translationWord['payment_method_cart'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.payment_method_cart }
									</td>
								</tr>
								<tr>
									<td>
										{ translationWord['payment_method_cash'] }
									</td>
									<td className="text-center">
										{ summaryStatistic.payment_method_cash }
									</td>
								</tr>
							</tbody>
							
						</table>
					</Card>
				</Col>
			}
		</>
       
    );

}

export default SummaryStatisticWeek;