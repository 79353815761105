import { gql } from '@apollo/client';

export const GET_ANALYTICS_MONTHLY = gql`
    query GetanAlyticsMonthly(
        $id: ID,
        $year: Int,
        $month: Int
    ){
        analytics_monthly(
            id: $id,
            year: $year,
            month: $month
        ){
            id
            year
            month
            income
            sales_count
            flowers_count
            price_product
            price_actual
            delivery_count
            delivery_price
            type_users
            type_manager
            type_fast
            type_cart
            payment_method_cart
            payment_method_cash
            created_at
        }
    }
`;


export const GET_ANALYTICS_SUMMARY = gql`
    query GetAnalyticsSummary {
        analytics_summary{
            price_product
            price_actual
            income
            sales_count
            delivery_count
            delivery_price
        }
    }
`;

export const GET_SUMMARY_STATISTIC = gql`
    query GetSummaryStatistic(
        $date_from: Date
        $date_to: Date
        $type: String
    ){
        summaryStatistic(
            date_from: $date_from,
            date_to: $date_to,
            type: $type
        ){
            id
            year
            month
            income
            sales_count
            price_product
            price_actual
            delivery_count
            delivery_price
            flowers_count
            type_users
            type_manager
            type_fast
            type_cart
            payment_method_cart
            payment_method_cash
            created_at
            title
        }
    }
`;