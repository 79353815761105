import React from "react";
import { OrdersTable } from "components/order-user";

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";


const OrdersPage = () => {

    useActiveMenuItem( ["orders-all"], ["orders"] );

    return(
            
        <PageWrapDefault className="page orders-page fullscreen">
            
            <OrdersTable />

        </PageWrapDefault>
        
    )

}

export default OrdersPage;