import { gql } from '@apollo/client';

export const ORDERS_MANAGER_DELETE = gql`
    mutation OrderManagerDelete(
        $id: ID!
    ) {
        orderManagerDelete(
            id: $id
        ){
            label
            message
        }
    }
`;

export const ORDERS_MANAGER_FIELD_UPDATE = gql`
    mutation OrderManagerFieldUpdate(
        $id: ID!
        $keyField: String
        $value: Int
    ) {
        orderManagerFieldUpdate(
            id: $id,
            keyField: $keyField,
            value: $value
        ){
            label
            message
            orderManager{
                id
                order_id
                manager_id
                type_flower
                shipping_type
                count_flowers
                price_product
                price_actual
                price_shipping
                income
                order_date_time
                order{
                    id
                    status
                    type
                    order_status
                    payment_method
                    type_delivery
                    user_email
                    user_name
                    user_phone
                    price_total
                    price_product
                    price_shipping
                    order_date_time
                }
            }
        }
    }
`;


export const ORDER_MANAGER_CREATE = gql`
    mutation OrderManagerCreate(
        $input: OrderManagerInput
    ) {
        orderManagerCreate(
            input: $input
        ){
            label
            message
            orderManager{
                id
                order_id
                manager_id
                type_flower
                shipping_type
                count_flowers
                price_product
                price_actual
                price_shipping
                income
                order_date_time
                order{
                    id
                    status
                    type
                    order_status
                    payment_method
                    type_delivery
                    user_email
                    user_name
                    user_phone
                    price_total
                    price_product
                    price_shipping
                    order_date_time
                }
            }
        }
    }
`;