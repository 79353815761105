import React from "react";
import { Card, Col } from 'antd';
import { useQuery } from "@apollo/client";

import { GET_ORDERS_USERS_STATISTIC } from "graphql/query/order-qql";

import { DashboardTwoTone, DollarTwoTone, ShoppingTwoTone } from '@ant-design/icons';


const UsersTop = ( { columns, variables } ) => {

    const { data : { ordersUsersStatistic } = {}, loading } = useQuery( GET_ORDERS_USERS_STATISTIC, {
        variables: { ...variables },
        fetchPolicy: 'cache-first'
    } );

    const title = variables.defaultCount ?? '10';

    const subTitle = () => {

        if( variables.typeField === 'price_total' ){
            return "сумма всех заказов";
        }

        if( variables.typeField === 'orders_count' ){
            return "количество всех заказов";
        }

        if( variables.typeField === 'delivery_count' ){
            return "количество всех доставок";
        }

    };

    const typeIcon = () => {

        if( variables.typeField === 'price_total' ){
            return <DollarTwoTone twoToneColor="green" />;
        }

        if( variables.typeField === 'orders_count' ){
            return <ShoppingTwoTone twoToneColor="orange" />;
        }

        if( variables.typeField === 'delivery_count' ){
            return <DashboardTwoTone />;
        }

    };

    const emptyData = () => {
	
        const emptyDataJSX = [];
    
        for ( var i = 0; i < variables.defaultCount ?? 10; i++ ){
    
            emptyDataJSX.push(
                <div key={ `empty-${i}-users-top` } className="user-item">
                    <div className="custom-sceleton my-1"></div>
                </div>
                
            )
    
        }
    
        return emptyDataJSX;
    
    };

    return(

        <Col { ...columns } key={ variables.typeField }>
            <Card type="inner" title={ `Топ-${title} Клиентов` } bordered={true}>
                <div className="w-100 text-center"><b>{ subTitle() }</b></div>
                
                { loading && emptyData() }

                { ordersUsersStatistic && ordersUsersStatistic.map( item => {
                        return (
                            <div key={ `${variables.typeField}-users-top-${item['id']}` } className="user-item">
                                
                                <p className="d-flex justify-content-between user-statistic">
                                    <span>{ typeIcon() } { item[variables.typeField] }</span> 
                                    <span>{ item['user_email'] }</span>
                                </p>
                                
                            </div>
                        );
                    } 
                )}

            </Card>
        </Col>

    )
};

export default UsersTop;