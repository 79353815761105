import React from "react";
import { ProductsTopTable } from "components/product";

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";


const ProductsTopPage = () => {

    useActiveMenuItem( ["top-flowers"] );

    return(
            
        <PageWrapDefault className="page products-top-page fullscreen">
            
            <ProductsTopTable />

        </PageWrapDefault>
        
    )

}

export default ProductsTopPage;