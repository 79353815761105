import React, { useState, useEffect } from "react";
import { Select, DatePicker, Space } from 'antd';

import { gqlBuilderWhere } from "utils";


const { Option } = Select;
const { RangePicker } = DatePicker;

const OrdersFilters = ( { setFilters, defaultFilters, orderStatus = 'Статус заказа' } ) => {

    const [ filterList, setFilterList ] = useState({
        typeDelivery: {},
        type: {},
        orderStatus: defaultFilters,
        paymentMethod: {},
        paymentStatus: {},
        createDate: {},
        shippingDate: {},
    });

    useEffect( () => {
        setFilters( gqlBuilderWhere( filterList ) );
    }, [filterList, setFilters] );


    return (
        <>
            <div className="w-100 mb-3">
                <Select
                    className="select-type"
                    onSelect={ ( value ) => {

                        if( value !== 'all' ){

                            setFilterList( { 
                                ...filterList,
                                type : {
                                    column: "TYPE",
                                    operator: "EQ",
                                    value: value
                                },
                            })

                        } else {

                            setFilterList( { 
                                ...filterList,
                                type : {},
                            })

                        }
                    }}
                    placeholder="Тип заказа"
                    allowClear={ true }
                    onClear={ () => {
                        setFilterList( { 
                            ...filterList,
                            type : {},
                        })
                    }}
                >
                    <Option value="fast">fast</Option>
                    <Option value="cart">cart</Option>
                    <Option value="manager">manager</Option>
                    <Option value="preorder">preorder</Option>
                </Select>

                <Select
                    className="select-order-status"
                    defaultValue={ orderStatus }
                    onSelect={ ( value ) => {

                        if( value !== 'all' ){

                            setFilterList( { 
                                ...filterList,
                                orderStatus : {
                                    column: "ORDER_STATUS",
                                    operator: "EQ",
                                    value: value
                                },
                            })

                        } else {

                            setFilterList( { 
                                ...filterList,
                                orderStatus : {},
                            })

                        }
                    }}
                    placeholder="Статус заказа"
                    allowClear={ true }
                    onClear={ () => {
                        setFilterList( { 
                            ...filterList,
                            orderStatus : {},
                        })
                    }}
                >
                    <Option value="processing">В обработке</Option>
                    <Option value="completed">Выполненные</Option>
                    <Option value="canceled">Отмененные</Option>
                </Select>

                <Select
                    className="select-payment-status"
                    onSelect={ ( value ) => {

                        if( value !== 'all' ){

                            setFilterList( { 
                                ...filterList,
                                paymentStatus : {
                                    column: "PAYMENT_STATUS",
                                    operator: "EQ",
                                    value: value
                                },
                            })

                        } else {

                            setFilterList( { 
                                ...filterList,
                                paymentStatus : {},
                            })

                        }
                    }}
                    placeholder="Статус оплаты"
                    allowClear={ true }
                    onClear={ () => {
                        setFilterList( { 
                            ...filterList,
                            paymentStatus : {},
                        })
                    }}
                >
                    <Option value="waiting">waiting</Option>
                    <Option value="paid">paid</Option>
                    <Option value="canceled">canceled</Option>
                </Select>

                <Select
                    className="select-payment-method"
                    onSelect={ ( value ) => {

                        if( value !== 'all' ){

                            setFilterList( { 
                                ...filterList,
                                paymentMethod : {
                                    column: "PAYMENT_METHOD",
                                    operator: "EQ",
                                    value: value
                                },
                            })

                        } else {

                            setFilterList( { 
                                ...filterList,
                                paymentMethod : {},
                            })

                        }
                    }}
                    placeholder="Метод оплаты"
                    allowClear={ true }
                    onClear={ () => {
                        setFilterList( { 
                            ...filterList,
                            paymentMethod : {},
                        })
                    }}
                >
                    <Option value="card">card</Option>
                    <Option value="cash">cash</Option>
                    <Option value="courier">courier</Option>
                </Select>

                <Select
                    className="select-shipping-type"
                    onSelect={ ( value ) => {

                        if( value !== 'all' ){

                            setFilterList( { 
                                ...filterList,
                                typeDelivery : {
                                    column: "TYPE_DELIVERY",
                                    operator: "EQ",
                                    value: value
                                },
                            })

                        } else {

                            setFilterList( { 
                                ...filterList,
                                typeDelivery : {},
                            })

                        }
                    }}
                    placeholder="Метод доставки"
                    allowClear={ true }
                    onClear={ () => {
                        setFilterList( { 
                            ...filterList,
                            typeDelivery : {},
                        })
                    }}
                >
                    <Option value="pickup">Самовывоз</Option>
                    <Option value="delivery">Доставка</Option>
                </Select>
            </div>
            <div>
                <span className="me-2">дата доставки :</span>
                <Space direction="vertical" size={12}>
                    <RangePicker 
                        onChange={ (dates, dateSrings) => {

                            if( dateSrings[0] && dateSrings[1] ){

                                dateSrings[0] = dateSrings[0] + ' 00:00:00';
                                dateSrings[1] = dateSrings[1] + ' 23:59:59';

                                setFilterList( { 
                                    ...filterList,
                                    shippingDate : {
                                        column: "SHIPPING_DATE",
                                        operator: "BETWEEN",
                                        value: dateSrings
                                    },
                                })

                            } else {

                                setFilterList( { 
                                    ...filterList,
                                    shippingDate : {},
                                })

                            }
                            
                        }} 
                    />
                </Space>
            </div>

            <div>
                <span className="me-2">дата заказа :</span>
                <Space direction="vertical" size={12}>
                    <RangePicker 
                        onChange={ (dates, dateSrings) => {

                            if( dateSrings[0] && dateSrings[1] ){

                                dateSrings[0] = dateSrings[0] + ' 00:00:00';
                                dateSrings[1] = dateSrings[1] + ' 23:59:59';

                                setFilterList( { 
                                    ...filterList,
                                    createDate : {
                                        column: "ORDER_DATE_TIME",
                                        operator: "BETWEEN",
                                        value: dateSrings
                                    },
                                })

                            } else {

                                setFilterList( { 
                                    ...filterList,
                                    createDate : {},
                                })

                            }
                            
                        }} 
                    />
                </Space>
            </div>

        </>
    );

};

export default OrdersFilters;