import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link } from "react-router-dom";

import './sidebar.scss';

const { SubMenu } = Menu;

const Sidebar = ( { appParams }) => {

    let {
        selectedKeys = ['dashboard'],
        openKeys     = ['dashboard']
    } =  appParams.activeMenu;

    const [ openSubMenu, setOpenSubMenu ] = useState( openKeys );

    useEffect( () => {

        setOpenSubMenu(openKeys)

    }, [appParams.activeMenu, openKeys] );

    return( 
        <>
            <Menu
                className=""
                mode="inline"
                selectedKeys={ selectedKeys }
                openKeys={ openSubMenu }
                onOpenChange={ ( keys ) => setOpenSubMenu(keys)}
                style={{ borderRight: 0 }}
            >
                <Menu.Item key="dashboard" >
                    <Link to="/">Главная</Link>
                </Menu.Item>

                <Menu.Item key="monthly-statistics"><Link to="/monthly-statistics">Отчет продаж</Link></Menu.Item>

                <SubMenu key="orders-manager" title="Продажи">

                    <Menu.Item key="orders-manager-all"><Link to="/orders-manager">Продажи</Link></Menu.Item>

                    <Menu.Item key="orders-manager-calendar"><Link to="/orders-manager/calendar">Календарь продаж</Link></Menu.Item>

                </SubMenu>

                <SubMenu key="orders" title="Заказы">

                    <Menu.Item key="orders-all"><Link to="/orders">Все</Link></Menu.Item>
                    <Menu.Item key="orders-new"><Link to="/orders/new">Новые</Link></Menu.Item>
                    <Menu.Item key="orders-new-today"><Link to="/orders/new-today">На сегодня</Link></Menu.Item>
                    <Menu.Item key="orders-new-tomorrow"><Link to="/orders/new-tomorrow">На завтра</Link></Menu.Item>

                </SubMenu>

                <Menu.Item key="clients"><Link to="/clients">Покупатели</Link></Menu.Item>

                <Menu.Item key="top-flowers"><Link to="products/top">Топ цветы</Link></Menu.Item>

                <Menu.Item key="log-orders"><Link to="/log-orders">Log Orders</Link></Menu.Item>

                <Menu.Item key="system-information"><Link to="/system-information">System Information</Link></Menu.Item>
                
            </Menu>
        </>
    );
};

export default Sidebar;