import { HomeAppPage } from "./home";
import { SystemInfoPage } from "./system-information"; 
import { MonthlyStatisticsPage } from "./monthly-statistics";
import { OrdersManagerPage, OrdersManagerCalendarPage } from "./order-manager";
import { OrdersPage, OrdersNewPage, OrdersNewTodayPage, OrdersNewTomorrowPage } from "./order";
import { ClientsPage } from "./clients";
import { ProductsTopPage } from "./products";
import { LoginPage, ForgotPasswordPage } from "./authorization";
import Page404 from "./404/404-page";

import "./pages.scss";


export const Pages = {
    HomeAppPage,
    SystemInfoPage,
    MonthlyStatisticsPage,
    OrdersManagerPage,
    OrdersManagerCalendarPage,
    OrdersPage,
    OrdersNewPage,
    OrdersNewTodayPage,
    OrdersNewTomorrowPage,
    ClientsPage,
    ProductsTopPage,
    Page404
};

export const GuestPages = {
    LoginPage,
    ForgotPasswordPage,
}