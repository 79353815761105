const translationWord = {
    income: 'Прибыль',
    sales_count: 'Кол. продаж',
    price_product: 'Сумма продаж',
    price_actual: 'Сумма цветов',
    flowers_count: 'Кол. цветов',
    delivery_count: 'Кол. доставок',
    delivery_price: 'Сумма доставок',
    type_users: 'Через сайт',
    type_manager: 'Менеджер',
    type_fast: 'Быстрые заказы',
    type_cart: 'Корзина',
    payment_method_cart: 'Оплата картой',
    payment_method_cash: 'Оплата наличными',
}

export default translationWord;