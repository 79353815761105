import { Table } from 'antd';

import { DollarTwoTone, ShoppingTwoTone,
    CarTwoTone, ShopTwoTone, ShoppingCartOutlined, 
    CreditCardTwoTone, RocketTwoTone, UserSwitchOutlined, 
    WalletOutlined } from '@ant-design/icons';


const clientsColumns = [
    Table.EXPAND_COLUMN,
    // {
    //     title: 'ID', dataIndex: 'id', columnIndex:'ID', sorter: true, align: 'center', className: 'table-id', width: "7%"
    // },
    {
        title: '@инфомарция', dataIndex: 'user_email', columnIndex:'USER_EMAIL', sorter: true, align: 'center', width: "25%"
    },
    {
        title: 'тел', dataIndex: 'user_phone', columnIndex: 'USER_PHONE', sorter: true, align: 'center', width: "15%"
    },
    {
        title: <><ShoppingTwoTone className='fs-18 w-100' twoToneColor="orange" /><span className='fs-11 w-100'>заказы</span></>, dataIndex: 'orders_count', columnIndex: 'ORDERS_COUNT', sorter: true, align: 'center', width: "5%"
    },
    {
        title: <><DollarTwoTone className='fs-18 w-100' twoToneColor="blue" /><span className='fs-11 w-100'>сумма</span></>, dataIndex: 'price_total', columnIndex: 'PRICE_TOTAL', sorter: true, align: 'center', width: "10%"
    },
    {
        title: <><DollarTwoTone className='fs-18 w-100' twoToneColor="green" /><span className='fs-11 w-100'>прибыль</span></>, dataIndex: 'income', columnIndex: 'INCOME', sorter: true, align: 'center', width: "10%"
    },
    {
        title: <><ShopTwoTone className='fs-18 w-100' twoToneColor="green"/><span className='fs-11 w-100'>самовывоз</span></>, dataIndex: 'pickup_count', columnIndex: 'PICKUP_COUNT', sorter: true, align: 'center', width: "5%"
    },
    {
        title: <><CarTwoTone className='fs-18 w-100' /><span className='fs-11 w-100'>доставка</span></>, dataIndex: 'delivery_count', columnIndex: 'DELIVERY_COUNT', sorter: true, align: 'center', width: "5%"
    },
    {
        title: <><ShoppingCartOutlined className='fs-18 w-100' style={{ color: '#6b9915' }} /><span className='fs-11 w-100'>Корзина</span></>, dataIndex: 'cart_count', columnIndex: 'CART_COUNT', sorter: true, align: 'center', width: "5%"
    },
    {
        title: <><RocketTwoTone className='fs-18 w-100' twoToneColor="purple" /><span className='fs-11 w-100'>Быстрый</span></>, sorter: true, dataIndex: 'delivery_count', columnIndex: 'DELIVERY_COUNT', align: 'center', width: "5%"
    },
    {
        title: <><CreditCardTwoTone className='fs-18 w-100' twoToneColor="#00008b" /><span className='fs-11 w-100'>Картой</span></>, dataIndex: 'delivery_count', columnIndex: 'DELIVERY_COUNT', sorter: true, align: 'center', width: "5%"
    },
    {
        title: <><WalletOutlined className='fs-18 w-100' style={{ color: '#e91e63' }}/><span className='fs-11 w-100'>Наличными</span></>, dataIndex: 'delivery_count', columnIndex: 'DELIVERY_COUNT', sorter: true, align: 'center', width: "5%"
    },
    {
        title: <><UserSwitchOutlined className='fs-18 w-100' style={{ color: '#5bb300' }} /><span className='fs-11 w-100'>курьер</span></>, dataIndex: 'delivery_count', columnIndex: 'DELIVERY_COUNT', sorter: true, align: 'center', width: "5%"
    }
];

const emptyData = () => {
	
	const emptyDataJSX = [];
    let my = 'my-3';

	for ( var i = 0; i < 50; i++ ){
    
        emptyDataJSX.push(
            {
                key: `empty-order-manager-${i}`,
                user_email: <div className={`custom-sceleton ${ my }`}></div>,
                user_phone: <div className={`custom-sceleton ${ my }`}></div>,
                orders_count: <div className={`custom-sceleton ${ my }`}></div>,
                price_total: <div className={`custom-sceleton ${ my }`}></div>,
                income: <div className={`custom-sceleton ${ my }`}></div>,
                pickup_count: <div className={`custom-sceleton ${ my }`}></div>,
                delivery_count: <div className={`custom-sceleton ${ my }`}></div>,
                cart_count: <div className={`custom-sceleton ${ my }`}></div>
            }
        );

	}

	return emptyDataJSX;

};

const clientsData = ( clients, variables ) => {

    if( !clients ){
        return emptyData();
    }

    return clients.map( client => {

        return {
            key: client.user_email,
            user_email: <>
                <p>{ client.user_name }</p>
                <p><b>{ client.user_email }</b></p>
            </>,
            email: client.user_email,
            user_phone: client.user_phone,
            orders_count: client.orders_count,
            price_total: client.price_total,
            income: client.income,
            pickup_count: client.pickup_count,
            delivery_count: client.delivery_count,
            cart_count: client.cart_count

        };

    });

}

export {
    clientsColumns,
    clientsData
};