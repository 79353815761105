import React from "react";
import { OrdersManagerTable } from "components/order-manager";

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";


const OrdersManagerPage = () => {

    useActiveMenuItem( ["orders-manager-all"], ["orders-manager"] );

    return(
            
        <PageWrapDefault className="page orders-manager-page fullscreen">
            
            <OrdersManagerTable />

        </PageWrapDefault>
        
    )

}

export default OrdersManagerPage;


// <Table
//     locale={{
//         emptyText: loading ? <Skeleton active={true} paragraph={{ rows: 30 }} /> : <Empty />
//     }}

//     onChange={ ( pagination, filters, { column, order } ) => {
        
//         if( column && order ){
            
//             setOrderBy([
//                 { 
//                     column: column.columnIndex, 
//                     order: order === "ascend" ? "ASC" : "DESC"  
//                 }
//             ])

//         } else { setOrderBy( [ defaultOrderBy ] ) }

//     }}
//     columns={ noticeFormsTableColumns }
//     dataSource={ noticeFormsData( noticeForms?.data ) }
//     tableLayout='fixed'
//     pagination={{
//         pageSize: paginatorInfo?.perPage,
//         total: paginatorInfo?.total,
//         hideOnSinglePage: paginatorInfo?.total / paginatorInfo?.perPage > 1,
//         current: currentPage,
//         onChange: ( page ) => {
//             let path = page === 1 ? `/notice-forms` : `/notice-forms/page/${ page }`
//             history.push( path );
//         }
//     }}
// />