import { gql } from '@apollo/client';

export const GET_ORDERS_USERS_STATISTIC = gql`
    query GetOrdersUsersStatistic(
        $typeField: OrderFieldEnum,
        $sort: SortOrder,
        $defaultCount: Int
    ) {
        ordersUsersStatistic(
            typeField: $typeField,
            sort: $sort,
            defaultCount: $defaultCount
        ){
            id
            user_email
            price_total
            orders_count
            delivery_count
        }
    }
`;


export const GET_ORDERS = gql`
    query GetOrders(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryOrdersOrderByOrderByClause!],
        $where: QueryOrdersWhereWhereConditions,
    ) {
        orders(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                status
                type
                order_status
                payment_status
                payment_method
                type_delivery
                user_email
                user_name
                user_phone
                price_total
                price_product
                price_shipping
                shipping_date
                shipping_time
                delivery_address
                receiver_name
                receiver_phone
                message
                locale
                products{
                    product_id
                    title
                    image
                    full_price
                    size
                    unit_price
                    qty
                }
                orderManager{
                    id
                    order_id
                    manager_id
                    type_flower
                    shipping_type
                    count_flowers
                    price_product
                    price_actual
                    price_shipping
                    income
                    order_date_time
                }
                orderLog{
                    id
                    order_id
                    remote_addr
                    http_user_agent
                    request_values
                    payment_info
                    created_at
                }
                order_date_time
            }
        }
    }
`;