const yearCurrent = new Date().getFullYear();
const yearStart = 2018;

const priorityPerMonth = {
    income: 40000,
    sales_count: 250,
    delivery_count: 50,
    delivery_price: 20000,
    type_users: 30,
    type_manager: 150
}

const arrayMonth = [ "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь" ];

const yearsList = () => {
    
    let years = [];
    
    for ( let i = yearCurrent; i >= yearStart; i-- ) {

        years.push( i );
        
    }

    return years;

};

const priorityFieldPerMonth = ( type ) => {

    return priorityPerMonth[type] || 0;

}

const percentColor = ( value ) => {
    
    if( value < 25  ){

        return '#f44336'; 

    } else if ( value > 25 && value < 40 ) {

        return '#ff9800'; 

    } else if ( value > 25 && value <= 40 ) {

        return '#ffc107'; 

    } else if ( value > 40 && value <= 50 ) {
        
        return '#00bcd4'; 

    } else if ( value > 50 && value <= 75 ) {

        return '#03a9f4'; 

    } else if ( value > 75 && value <= 90 ) {

        return '#8bc34a'; 

    } else if ( value > 90 && value <= 99 ) {

        return '#4caf50'; 

    } else if ( value >= 100 ) {

        return '#009688'; 

    }
    
}


export{
    arrayMonth,
    percentColor,
    priorityFieldPerMonth,
    yearsList
}