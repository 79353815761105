import React from 'react';
import { useQuery } from "@apollo/client";
import { Card, Col, Row, Spin, Statistic } from 'antd';

import { GET_ANALYTICS_SUMMARY } from "graphql/query/statistic-ggl";


const AnalyticsSummaryWidget = () => {

    const { data : analytics_summary = {}, loading } = useQuery( GET_ANALYTICS_SUMMARY );

    const analyticsData = analytics_summary && !!analytics_summary['analytics_summary'] ? analytics_summary['analytics_summary'] : [];

    const columns = {
        span : 12,
        lg: 8,
        xl: 4,
        xxl: 4
    };

    return (
        <>
            <Row gutter={16} className="mb-4 analytics-summary">
                
                <Col { ...columns } >
                    <Card className="text-center">
                        { analytics_summary && !!analyticsData?.price_product &&
                            <>
                                <p>Всего продано</p>
                                <Statistic groupSeparator=" " value={ analyticsData.price_product } />
                            </>
                        }
                        { loading && <Spin /> }
                    </Card>
                </Col>

                <Col { ...columns } >
                    <Card className="text-center">
                        { analytics_summary && !!analyticsData?.price_actual &&
                            <>
                                <p>Закупка</p>
                                <Statistic groupSeparator=" " value={ analyticsData.price_actual } />
                            </>
                        }
                        { loading && <Spin /> }
                    </Card>
                </Col>

                <Col { ...columns } >
                    <Card className="text-center">
                        { analytics_summary && !!analyticsData?.income &&
                            <>
                                <p>Прибыль</p>
                                <Statistic groupSeparator=" " value={ analyticsData.income } />
                            </>
                        }
                        { loading && <Spin /> }
                    </Card>
                </Col>

                <Col { ...columns } >
                    <Card className="text-center">
                        { analytics_summary && !!analyticsData?.delivery_count &&
                            <>
                                <p>Доставки</p>
                                <Statistic groupSeparator=" " value={ analyticsData.delivery_count } />
                            </>
                        }
                        { loading && <Spin /> }
                    </Card>
                </Col>

                <Col { ...columns } >
                    <Card className="text-center">
                        { analytics_summary && !!analyticsData?.delivery_price &&
                            <>
                                <p>Сумма доставок</p>
                                <Statistic groupSeparator=" " value={ analyticsData.delivery_price } />
                            </>
                        }
                        { loading && <Spin /> }
                    </Card>
                </Col>

                <Col { ...columns } >
                    <Card className="text-center">
                        { analytics_summary && !!analyticsData?.sales_count &&
                            <>
                                <p>Кол. продаж</p>
                                <Statistic groupSeparator=" " value={ analyticsData.sales_count } />
                            </>
                        }
                        { loading && <Spin /> }
                    </Card>
                </Col>

            </Row>
        </>
    );

}

export default AnalyticsSummaryWidget;


/*
    return (
        <>
            <div className="bootstrap-wrapper analytics-summary">
                <div className="container-fluid1">
                    
                    <div className="row my-4 analytics-summary">
                        
                        <div className="col-xl-2 col-md-4 col-sm-4 col-6">
                            <Card className="text-center">
                                { analytics_summary && !!analyticsData?.price_product &&
                                    <>
                                        <p>Всего продано</p>
                                        <Statistic groupSeparator=" " value={ analyticsData.price_product } />
                                    </>
                                }
                                { loading && <Spin /> }
                            </Card>
                        </div>

                        <div className="col-xl-2 col-md-4 col-sm-4 col-6">
                            <Card className="text-center">
                                { analytics_summary && !!analyticsData?.price_actual &&
                                    <>
                                        <p>Закупка</p>
                                        <Statistic groupSeparator=" " value={ analyticsData.price_actual } />
                                    </>
                                }
                                { loading && <Spin /> }
                            </Card>
                        </div>

                        <div className="col-xl-2 col-md-4 col-sm-4">
                            <Card className="text-center">
                                { analytics_summary && !!analyticsData?.income &&
                                    <>
                                        <p>Прибыль</p>
                                        <Statistic groupSeparator=" " value={ analyticsData.income } />
                                    </>
                                }
                                { loading && <Spin /> }
                            </Card>
                        </div>

                        <div className="col-xl-2 col-md-4 col-sm-4">
                            <Card className="text-center">
                                { analytics_summary && !!analyticsData?.delivery_count &&
                                    <>
                                        <p>Доставки</p>
                                        <Statistic groupSeparator=" " value={ analyticsData.delivery_count } />
                                    </>
                                }
                                { loading && <Spin /> }
                            </Card>
                        </div>

                        <div className="col-xl-2 col-md-4 col-sm-4">
                            <Card className="text-center">
                                { analytics_summary && !!analyticsData?.delivery_price &&
                                    <>
                                        <p>Сумма доставок</p>
                                        <Statistic groupSeparator=" " value={ analyticsData.delivery_price } />
                                    </>
                                }
                                { loading && <Spin /> }
                            </Card>
                        </div>

                        <div className="col-xl-2 col-md-4 col-sm-4">
                            <Card className="text-center">
                                { analytics_summary && !!analyticsData?.sales_count &&
                                    <>
                                        <p>Кол. продаж</p>
                                        <Statistic groupSeparator=" " value={ analyticsData.sales_count } />
                                    </>
                                }
                                { loading && <Spin /> }
                            </Card>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );

}

*/