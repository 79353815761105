import React from 'react';
import { useMutation } from "@apollo/client";
import moment from 'moment';

import { Form, Input, InputNumber, Button, DatePicker, Select } from 'antd';

import { ORDER_MANAGER_CREATE } from 'graphql/mutation/order-manager-qql';
import { GET_ORDERS_MANAGER }   from 'graphql/query/order-manager-qql';
import { errorNotification, successNotification } from "components/request-result";


const { Option } = Select;

const OrderManagerCreateForm = ( { variables, order = false } ) => {

    const [form] = Form.useForm();

    const [orderManagerCreate, { loading }] = useMutation( ORDER_MANAGER_CREATE,
        {
            update(cache, { data }) {

                const {
                    orderManagerCreate : {
                        label,
                        message,
                        orderManager
                    }
                } = data;

                if( order ){

                    cache.modify({
                        id: cache.identify( order ),
                        fields: {
                            orderManager(){
                                return { __ref: `${ orderManager.__typename }:${ orderManager.id }`};
                            }
                        },
                    });

                } else {

                    const { ordersManager } = cache.readQuery({
                        query: GET_ORDERS_MANAGER,
                        variables
                    });

                    cache.writeQuery({
                        query: GET_ORDERS_MANAGER,
                        variables,
                        data: {
                            ordersManager: {
                                ...ordersManager,
                                paginatorInfo:{
                                    ...ordersManager.paginatorInfo,
                                    total: ordersManager.paginatorInfo.total + 1
                                },
                                data : [
                                    orderManager,
                                    ...ordersManager.data
                                ]
                            }
                        },
                    });

                }

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return (
        <>
            <Form
                form={form}
                key="order-manager-create"
                onFinish={ ( values ) => {
                    orderManagerCreate({
                        variables: {
                            input: { ...values }
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                    form.resetFields();
                }}
                className="order-manager-create"
            >
                <div className='d-flex justify-content-between field-row'>
                    <span className="w-50 fw-600">Цена доставки</span>
                    <Form.Item 
                        name="order_date_time"
                        className='w-50'
                        format={"DD-MM-YYYY"}
                        initialValue={ order ? moment( order.shipping_date, 'DD-MM-YYYY HH:MM:SS' )  : moment( new Date(), 'DD-MM-YYYY HH:MM:SS' ) }
                    >
                        <DatePicker className='w-100'
                            // defaultValue={ order ? moment( "10-03-2022", 'YYYY-MM-DD' )  : moment( new Date(), 'YYYY-MM-DD H:mm:ss' ) }
                        />
                    </Form.Item>
                </div>

                <div className='d-flex justify-content-between field-row'>
                    <span className="w-50 fw-600">ID заказа</span>
                    <Form.Item 
                        name="order_id"
                        className='w-50'
                        initialValue={ order?.id }
                    >
                        <Input className='text-center'/>
                    </Form.Item>
                </div>

                <div className='d-flex justify-content-between field-row'>
                    <span className="w-50 fw-600">Тип цветов</span>
                    <Form.Item 
                        name="type_flower"
                        initialValue="rose"
                        className="w-50 text-center"
                    >
                        <Select 
                            defaultValue={ order.orderManager?.type_flower ?? 'rose' }
                            key="type-flower"
                        >
                            <Option value="rose">Роза</Option>
                            <Option value="buket">Букет</Option>
                            <Option value="wedding">СБ</Option>
                            <Option value="mix">Микс</Option>
                        </Select>
                    </Form.Item> 
                </div>

                <div className='d-flex justify-content-between field-row'>
                    <span className="w-50 fw-600">Кол. цветов</span>
                    <Form.Item 
                        name="count_flowers"
                        className="w-50"
                        rules={[
                            { required: true, message: 'поле не может быть пустым' }
                        ]}
                    >
                        <InputNumber min={1} className="w-100 text-center"/>
                    </Form.Item>
                </div>

                <div className='d-flex justify-content-between field-row'>
                    <span className="w-50 fw-600">Стоимость</span>
                    <Form.Item 
                        name="price_product"
                        className="w-50"
                        initialValue={ order.price_product ? parseInt( order.price_product ) : '' }
                        rules={[
                            { required: true, message: 'поле не может быть пустым' }
                        ]}
                    >
                        <InputNumber min={1} className="w-100 text-center" />
                    </Form.Item>
                </div>

                <div className='d-flex justify-content-between field-row'>
                    <span className="w-50 fw-600">Факт. цена</span>
                    <Form.Item 
                        name="price_actual"
                        className="w-50"
                        rules={[
                            { required: true, message: 'поле не может быть пустым' }
                        ]}
                    >
                        <InputNumber min={1} className="w-100 text-center"/>
                    </Form.Item>
                </div>
                
                <div className='d-flex justify-content-between field-row'>
                    <span className="w-50 fw-600">Цена доставки</span>
                    <Form.Item 
                        name="price_shipping"
                        className="w-50"
                        initialValue={ order.price_shipping ? parseInt( order.price_shipping ) : '' }
                        rules={[
                            { required: true, message: 'поле не может быть пустым' }
                        ]}
                    >
                        <InputNumber min={0} className="w-100 text-center"/>
                    </Form.Item>
                </div>

                <Form.Item className='text-right mb-0 mt-2'>
                    <Button
                        className='custom'
                        loading={ loading }
                        disabled={ loading }
                        type="primary"
                        htmlType="submit"
                    >
                        добавить
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default OrderManagerCreateForm;