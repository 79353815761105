import React from "react";
import { useQuery } from "@apollo/client";
import { Row, Table, Skeleton, Empty } from 'antd';
import { productsTopColumns, productsData } from "./products-top-table-helper";

const dataSource = [
		{
			key: '1',
			name: 'Mike',
			age: 32,
			address: '10 Downing Street',
		},
		{
			key: '2',
			name: 'John',
			age: 42,
			address: '10 Downing Street',
		},
	];
	

const ProductsTopTable = () => {

	return(
        <> 
             <div className="table-header p-3">

                <Row className="title-header mb-2">

                    <h1 className="fs-18">Топ цветы</h1>

                </Row>

            </div>

            <Table 
                className="products-table"
                dataSource={ dataSource } 
                columns={ productsTopColumns } 
                scroll={ { x:true } }
                bordered={ true }
                tableLayout='fixed'
            />
        </>
	);
}

export default ProductsTopTable;