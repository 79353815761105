import React from "react";
import { OrderManagerCalendar } from "components/order-manager";

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";

const OrdersManagerCalendarPage = () => {

    useActiveMenuItem( ["orders-manager-calendar"], ["orders-manager"] );

    return(
            
        <PageWrapDefault className="orders-manager-calendar-page fullscreen">
            
            <OrderManagerCalendar />

        </PageWrapDefault>
        
    )

}

export default OrdersManagerCalendarPage;