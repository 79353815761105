import React, { useState, useEffect } from "react";
import { Select, DatePicker, Space } from 'antd';

import { gqlBuilderWhere } from "utils";


const { Option } = Select;
const { RangePicker } = DatePicker;

const OrdersManagerFilters = ( { setFilters } ) => {

    const [ filterList, setFilterList ] = useState({
        shippingType : {},
        createDate : {},
    });

    useEffect( () => {
        setFilters( gqlBuilderWhere( filterList ) );
    }, [filterList, setFilters] );


    return (
        <>
            <Select
                className="select-shipping-type"
                onSelect={ ( value ) => {

                    if( value !== 'all' ){

                        setFilterList( { 
                            ...filterList,
                            shippingType : {
                                column: "SHIPPING_TYPE",
                                operator: "EQ",
                                value: value
                            },
                        })

                    } else {

                        setFilterList( { 
                            ...filterList,
                            shippingType : {},
                        })

                    }
                }}
                placeholder="Тип доставки"
                allowClear={ true }
                onClear={ () => {
                    setFilterList( { 
                        ...filterList,
                        shippingType : {},
                    })
                }}
            >
                <Option value="pickup">Самовывоз</Option>
                <Option value="delivery">Доставка</Option>
            </Select>

            <div>
                <span className="me-2">Период :</span>
                <Space direction="vertical" size={12}>
                    <RangePicker 
                        onChange={ (dates, dateSrings) => {

                            if( dateSrings[0] && dateSrings[1] ){

                                dateSrings[0] = dateSrings[0] + ' 00:00:00';
                                dateSrings[1] = dateSrings[1] + ' 23:59:59';

                                setFilterList( { 
                                    ...filterList,
                                    createDate : {
                                        column: "ORDER_DATE_TIME",
                                        operator: "BETWEEN",
                                        value: dateSrings
                                    },
                                })

                            } else {

                                setFilterList( { 
                                    ...filterList,
                                    createDate : {},
                                })

                            }
                            
                        }} 
                    />
                </Space>
            </div>

        </>
    );

};

export default OrdersManagerFilters;