import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Popconfirm, Tooltip } from 'antd';

import { ORDERS_EDIT_STATUS_UPDATE } from "graphql/mutation/order-qql";

import { errorNotification, successNotification } from "components/request-result";
import { CheckSquareOutlined } from '@ant-design/icons';


const OrderApproveButton = ( { order } ) => {

    const [visible, setVisible] = React.useState(false);

    const [orderEditStatus, { loading }] = useMutation( ORDERS_EDIT_STATUS_UPDATE,
        {
            update( cache, { data } ) {

                const {
                    orderEditStatus : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });
                
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <>
            <Popconfirm
                cancelText="Нет"
                okText="Да"
                title={ <><b>Завершить заказ { order.id }</b> ?</> }
                visible={ visible }
                onConfirm={ () => {
                        orderEditStatus({
                            variables: {
                                id: order.id,
                                type: 'approve'
                            }
                        })
                        setTimeout(() => {
                            setVisible(false);
                        }, 1000);
                    }
                }
                okButtonProps={ { loading } }
                onCancel={ () => setVisible( false ) }
            >
                <Button
                    className="order-button-approve"
                    type="text"
                    onClick={ () => setVisible( true ) }
                >
                    <Tooltip placement="top" title="завершить заказ">
                        <CheckSquareOutlined className="fs-20" />
                    </Tooltip>

                </Button>
            </Popconfirm>
        </>
    );

};

export default OrderApproveButton;