import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Card, Col, Row, Calendar } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';

import { GET_ORDERS_MANAGER_CALENDAR } from "graphql/query/order-manager-qql";

import OrderManagerSummaryStatistic from "./summary-statistic";
import OrderManagerDay from "./order-manager-day";
import { TagsFilled } from '@ant-design/icons';


const columns1 = {
    span:24,
    md : 24,
    lg: 14
};
const columns2 = {
    span:24,
    md : 24,
    lg: 10
};

const OrderManagerCalendar = () => {

    const [ selectMonthYear, setSelectMonthYear ] = useState( moment().format("YYYY-MM-DD") );
    const [ selectedValue, setSelectedValue ]     = useState( moment() );

    const { data : { ordersManagerCalendar } = {} } = useQuery( GET_ORDERS_MANAGER_CALENDAR, {
        variables: { 
            date: selectMonthYear
        },
        fetchPolicy: 'cache-first'
    } );

    const onPanelChange = ( value ) => {

        setSelectMonthYear( value.format( 'YYYY-MM-DD' ) );

    };

    const onSelect = ( value ) => {

        setSelectedValue( value );
        
    };

    const dateCellRender = ( value ) => {

        if( !ordersManagerCalendar ){
            return null;
        }

        return ( ordersManagerCalendar.map( ( item ) => {

            if( item.orderDay === value.format( 'YYYY-MM-DD' ) ){

                return (
                    <div key={ item.id } className="font-family-numeric">
                        <p className="badge income fs-16">{ item.income }</p>
                        <p className="badge sales-count fs-16"><TagsFilled  className="icon"/> { item.salesCount }</p>
                    </div>
                );

            }

        }));

    };

    return(

        <Row gutter={16} className="mb-3">

            <Col { ...columns1 }>
                <Calendar 
                    className="order-manager-calendar"
                    dateCellRender={ dateCellRender } 
                    onPanelChange={ onPanelChange }
                    onSelect={ onSelect }
                />
            </Col>

            <Col { ...columns2 } className="w-50">
                <Card type="inner" className="fs-15 font-family-numeric text-center" title={ selectedValue.format('DD MMMM YYYY') } bordered={true}>
                    
                    <OrderManagerDay selectedValue={ selectedValue } />
                    <OrderManagerSummaryStatistic columns2={ columns2 } selectedValue={ selectedValue } />

                </Card>
            </Col>

        </Row>
    )


};

export default OrderManagerCalendar;